import React, { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import mapChart from 'highcharts/modules/map';
import { Grid, Typography, Tooltip as MUITooltip, Button, Box, makeStyles, createStyles } from '@material-ui/core';
import Layout from './Layout';
import { countryMappings, getCountryColor, ImpactVariables } from '../utils/constants';
import fetchCSVData from '../utils/fetchCSVData';
import HighchartsExport from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import { InfoOutlined } from '@material-ui/icons';
// Required to auto-display on-screen keyboard (to bring up Ctrl key for Country multi-select)
// For MUI Slider Animation - See Codepen:  https://codesandbox.io/s/66983676control-the-material-ui-slider-with-a-play-and-stop-buttons-in-react-js-m9349?file=/demo.tsx:1169-1592
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { getLineStyle } from '../utils/utilities';
import BaseFolderContext from '../config/globalVariables';
import { getCombinedData } from './mapPage/EnergyWorldMapGrouping';
import MapPageVariableSelection from './mapPage/variableSelection';
import MapPageWorldMap from './mapPage/MapPageWorldMapCustom';
import LineGraphMapPage from './mapPage/MapPageLineGraph';
import ScenarioSelectionMultiple from './ScenarioSelectionMultiple';
import rgbaData from '../utils/rgba100MapPage.json';
import BusinessScenarioSelectionMultiple from './BusinessScenarioSelectionMultiple';
import { hideDataTable } from '../utils/resetDataTable';

type CSVData = Record<string, number> & { Date: number };
type LineChartData = {
	name: string;
	data: number[];
	color?: string;
	dashStyle?: any;
	visible?: boolean;
	type?: string;
};

type Props = {
	colour: string;
	colourTitle: string;
	colourDark?: string;
	variables: ImpactVariables[];
	pageTitle: string;
	dataPrefix: string;
	infoTextHead: string;
	infoTextReset: string;
	lineGraphVariables?: any;
	userType?: string;
};

type MapData = [string, number][];

mapChart(Highcharts);
HighchartsExport(Highcharts);
HighchartsExportData(Highcharts);

const MapPageBusiness = ({
	colour,
	variables,
	dataPrefix,
	pageTitle,
	infoTextHead,
	infoTextReset,
	colourTitle,
	colourDark,
	lineGraphVariables,
	userType,
}: Props): JSX.Element => {
	const defaultValues = {
		variable: [variables[1].value],
		//		minYear: 2021,		// this screws up "autoplot" on first render in MapPage eg MacroMap.ts plots just zero response
		minYear: 1975,		// this ensures that at least some "autoplot" occurs on first render in MapPage eg MacroMap.ts
		selectedYear: 2025,
		title: '',
		scenarioType: userType === 'student' ? 'sf1' : 'bf1',
		scenarioSize: userType === 'student' ? ['sf1a'] : ['bf1a'],
		format: 'pct',
		maxColour: '#000000',
	};

	const [variable, setVariable] = useState<string[]>(defaultValues.variable);
	const [mapData, setMapData] = useState<MapData>([]);
	const [mapDataHover, setMapDataHover] = useState<MapData>([]);
	const [lineData, setLineData] = useState<LineChartData[]>([]);
	const [minYear, setMinYear] = useState<number>(defaultValues.minYear);

	// MT - Is this stopping MapPage Line chart plotting after 2023 ?? - need to plot 1975-2050 for "cen" (where data available)
	const [callbackSelectedYear, setCallbackSelectedYear] = useState<number>(defaultValues.selectedYear);
	const [title, setTitle] = useState<string>(defaultValues.title);
	const [scenarioType, setScenarioType] = useState<string>(defaultValues.scenarioType);
	const [scenarioSize, setScenarioSize] = useState<string[]>(defaultValues.scenarioSize);
	const [format, setFormat] = useState<string>(defaultValues.format);
	const [maxColour, setMaxColour] = useState<string>(defaultValues.maxColour);
	const [loading, setLoading] = useState<boolean>(false);
	const data = useRef<CSVData[]>([]);
	const [isCallbackAnimationClicked, setCallbackIsAnimationClicked] = React.useState(false);
	const [resetValues, setResetValues] = React.useState(false);
	const directionRef = useRef<'pause' | 'play' | ''>('');
	const [selectedCountriesForGraph, setSelectedCountriesForGraph] = useState<CSVData[]>([]);
	const [lineGraphVariable, setLineGraphVariable] = useState<any>(['WD']);
	const [csvDataFromAllScen, setCsvDataFromAllScen] = useState<[]>([]);
	const [shockImpactStartYr, setShockImpactStartYr] = useState<number>(2025);
	// Ensure this year has non zero scenario responses otherwise map data won't display properly.
	// eg. Setting 2023 will cause data gaps on Map + Mouseover shows zero data.


	const useStyles = makeStyles((theme) => createStyles({
		transparentButton: {
			'&:hover': {
				backgroundColor: 'transparent'
			}
		}
	}))

	const styles = useStyles();

	const onChangeClimateVariable = (value: any) => {
		setVariable(value);
		setSelectedCountriesForGraph([]); //to reset all selected countries when the variable changes
		setLineGraphVariable(['WD']);
		// if (value?.substring(value.lastIndexOf('_') + 1, value?.length).startsWith('z')) {
		// 	setFormat('dif');
		// }
	};

	const onChangeLineGraphVariable = (value: any) => {
		setLineGraphVariable(value);
		csvDataFromAllScen?.map((csvInfo: any) => {
			processLineChart(csvInfo?.data, csvInfo?.name, value, csvInfo?.varName);
		});
	};

	const getShockImpactYr = (scenarioType: string) => {
		return scenarioType === 'cen' ? 1977 : 2025
	}

	useEffect(() => {
		const newShockImpactYr = getShockImpactYr(scenarioType);
		setShockImpactStartYr(newShockImpactYr);
		setCallbackSelectedYear(newShockImpactYr);
	}, [scenarioType]);

	const handleAllReset = () => {
		// Stop animation if it's running
		if (directionRef.current === 'play') {
			directionRef.current = 'pause';
			setCallbackIsAnimationClicked(false); // Using the correct state setter
			onAnimationChange(); // Call animation change handler
		}

		setResetValues(true);
		setVariable([variables[1].value]);
		// 		setMinYear(2021);
		setMinYear(1975);

		// Update the selected year to current shockImpactStartYr
		setCallbackSelectedYear(shockImpactStartYr);
		setScenarioType(userType == 'student' ? 'sf1' : 'bf1');
		setScenarioSize(defaultValues.scenarioSize);
		setLineGraphVariable(['WD']);
		setFormat('pct');
		setMaxColour('#000000');
		setSelectedCountriesForGraph([]);
		setLineData([]);
		tempAllCSVData = [];
		setCsvDataFromAllScen([]);
		setMapDataHover([]);

		// Clear all line data arrays
		lineDataValues = [];
		lineDataGroupedCountryValues = [];
		lineWorldData = [];

		hideDataTable(2)
		//This reset needs to be fixed. As we click on replay the animation starts automatically.
		// const replayButton = document.querySelector('button[name="replay"]');

		// if (replayButton) {
		// 	let click = new MouseEvent('click', {
		// 		view: window,
		// 		bubbles: true,
		// 		cancelable: true,
		// 	});
		// 	replayButton.dispatchEvent(click);
		// } else {
		// 	console.log('Menu item not found.');
		// }
	};

	let tempRgbaData: any = rgbaData;

	let lineDataValues: any = [];
	let lineDataGroupedCountryValues: any = [];
	let lineWorldData: any = [];

	const processScenarioData = (data: CSVData[]) => {
		return data.map(row => {
			const processedRow = { ...row };

			// Iterate through all properties except 'Date'
			Object.keys(row).forEach(key => {
				if (key !== 'Date' && row[key] === 0) {
					processedRow[key] = 0.00001;
				}
			});

			return processedRow;
		});
	};

	const processLineChart = (newData: CSVData[], scenSize?: any, graphVariables?: any, varSelected?: any) => {
		// Select the data for the world and for the US.
		let varStubTemp: any = variables.find(({ value }) => value === varSelected)?.varStub ?? '';
		const worldData: any = newData.map(
			(value) =>
				//				value[Object.keys(value).find((key) => key.startsWith('WD') || key.startsWith('zWD') || key.endsWith('_WD'))!]
				value[Object.keys(value).find((key) => key.endsWith('_WD'))!]
		);

		// How to ensure "zWD" is also recognised as variable name prefix for Ann Growth difference of GLobal variables ???
		// const worldData = newData.map((value) => value[Object.keys(value).find((key) => key.startsWith('zWD'))!]);
		let differenceValue = isCallbackAnimationClicked
			? callbackSelectedYear - minYear
			: scenarioType == 'cen'
				? 2050 - 1977
				//			? 2050 - 2021
				: 2050 - minYear;

		// const finalWorldData = worldData.map((info: any, index: any) =>
		// 	differenceValue > 5 && index > differenceValue ? (worldData[index] = null) : (worldData[index] = worldData[index])
		// );
		let tempLineData = [...lineDataValues];
		let templineDataGroupedCountryValues = [...lineDataGroupedCountryValues];
		let tempWorldData = [...lineWorldData];
		// tempLineData?.push({
		// 	name: `${varStubTemp}-World-${scenSize ? scenSize : scenarioSize[0]}`,
		// 	data: finalWorldData,
		// 	color: colourDark,
		// 	dashStyle: getLineStyle[scenSize?.slice(3)],
		// 	visible: variable?.length == 1 ? false : true,
		// 	type: 'world',
		// });

		if (graphVariables?.length > 0) {
			graphVariables.forEach((varValue: any, index: any) => {
				const countryData: any = newData.map(
					(value: any) =>
						value[Object.keys(value).find((key: any) => key.substring(key.lastIndexOf('_')) == `_${varValue}`)!]
				);
				const finalData = countryData.map((info: any, index: any) =>
					index > differenceValue ? (countryData[index] = null) : (countryData[index] = countryData[index])
				);

				let graphColor: any = getCountryColor(`${varValue}`);

				if (finalData?.length) {
					// if (graphVariables?.length == 1 && graphVariables[0] == 'WD' && selectedCountriesForGraph?.length == 0) {
					// 	return;
					// }
					templineDataGroupedCountryValues?.push({
						name:
							scenarioType !== 'cen' && scenSize
								? `${varStubTemp}-${varValue == 'WD' ? 'World' : varValue}-${scenSize ? scenSize : scenarioSize[0]}`
								: `${varStubTemp}-${varValue == 'WD' ? 'World' : varValue}`,
						data: finalData,
						color: tempRgbaData[`${index?.toLocaleString() + Math.floor(Math.random() * 100) + 1}`],
						dashStyle: getLineStyle[scenSize?.slice(3)],
						visible: true,
						type: 'variable',
					});
					lineDataGroupedCountryValues = templineDataGroupedCountryValues;
				}
			});
		}
		if (selectedCountriesForGraph.length != 0) {
			selectedCountriesForGraph.forEach((countryObj: any, index: any) => {
				// let graphColor: any = getCountryColor(countryObj?.suffix);
				const countryData: any = newData.map(
					(value) =>
						value[
						Object.keys(value).find((key: any) => key.substring(key.lastIndexOf('_')) == `_${countryObj?.suffix}`)!
						]
				);
				const finalData = countryData.map((info: any, index: any) =>
					index > differenceValue ? (countryData[index] = null) : (countryData[index] = countryData[index])
				);

				tempLineData.push({
					name:
						scenarioType !== 'cen'
							? `${varStubTemp}-${countryObj.country}-${scenSize ? scenSize : scenarioSize[index]}`
							: `${varStubTemp}-${countryObj.country}-${userType == 'student' ? 'sf1a' : 'bf1a'}`,
					data: finalData,
					color: tempRgbaData[`${index + 2?.toLocaleString()}`],
					dashStyle: getLineStyle[scenSize?.slice(3)],
					visible: true,
					type: 'country',
				});
			});
			lineDataValues = tempLineData;
			// setLineData(tempLineData?.concat(templineDataGroupedCountryValues?.concat(tempWorldData)));
			setLineData(tempLineData?.concat(templineDataGroupedCountryValues)); //?.concat(tempWorldData)));
		} else {
			// let mapColor: any = '';
			// variables.map(({ value, color }, index) => {
			// 	if (value === varSelected) {
			// 		mapColor = index == 1 ? color : tempRgbaData[`${index?.toLocaleString()}`];
			// 	}
			// });
			// tempWorldData?.push({
			// 	name:
			// 		scenarioType !== 'cen' && scenSize
			// 			? `${varStubTemp}-World-${scenSize ? scenSize : scenarioSize[0]}`
			// 			: `${varStubTemp}-World`,
			// 	data: finalWorldData,
			// 	color: mapColor,
			// 	dashStyle: getLineStyle[scenSize?.slice(3)],
			// 	visible: true,
			// 	type: 'world',
			// });
			// lineWorldData = tempWorldData;

			//setLineData(templineDataGroupedCountryValues);
			setLineData(templineDataGroupedCountryValues);
			// if (!graphVariables?.length) {
			// 	setLineData(templineDataGroupedCountryValues);
			// } else {
			// 	setLineData(templineDataGroupedCountryValues?.concat(tempWorldData));
			// }
		}
	};

	let tempAllCSVData: any = [];

	const fetchData = async (scenSize: any, varSelected: any) => {
		if (dataPrefix == 'EnergyMap') {
			getCombinedData();
		}
		try {
			setLoading(true);
			let mapColor: any = '';
			variables.map(({ value, color }, index) => {
				if (value === varSelected) {
					mapColor = index == 1 ? color : tempRgbaData[`${index?.toLocaleString()}`];
				}
			});
			setMaxColour(mapColor);
			const csvData = await fetchCSVData(
				// Leading slash included
				//					`/csv_scen/${BaseFolderContext.baseCSVFolder}/${dataPrefix}/${scenarioType}/${scenarioSize}-${variable}.CSV?dummy=${Math.random()}`
				// Pre EnergyMap amendments
				//				`csv_scen/${BaseFolderContext.baseCSVFolder}/${dataPrefix}/${scenarioType}/${scenarioSize}-${variable}.CSV?dummy=${Math.random()}`
				// "LEV" dataFormat hardcoded (allow "lev", "pct, "dif" lower case too ??)
				`csv_scen/${BaseFolderContext.baseCSVFolder}/${dataPrefix}/${scenarioType}/${scenSize}-${varSelected}_${scenarioType == 'cen' ? 'LEV' : format?.toUpperCase()
				}.CSV?dummy=${Math.random()}`
				//				\csv_scen\EnergyMap\sf1\sf1a-GV_EDPCT.CSV
			);
			if (scenarioType == 'cen') {
				setMinYear(1977);
				//				setMinYear(2021);
			} else {
				setMinYear(Math.min(...csvData.map(({ Date }) => Date)));
			}

			// remove slice to plot the whole data from 1975
			// data.current = csvData;
			data.current = scenarioType != 'cen' ? csvData : csvData?.slice(2); //to start plotting from 1977 two rows down from start
			data.current = processScenarioData(data.current);
			if (tempAllCSVData?.length) {
				tempAllCSVData?.forEach((csvTemp: any) => {
					if (csvTemp?.name == scenSize && csvTemp?.varName == varSelected) {
						csvTemp.data = data.current;
					} else {
						tempAllCSVData.push({ name: scenSize, data: data.current, varName: varSelected });
					}
				});
			} else {
				tempAllCSVData.push({ name: scenSize, data: data.current, varName: varSelected });
			}
			setCsvDataFromAllScen(tempAllCSVData);
			// setCSVData(data.current);
			// setCSVData(csvData?.slice(2));
			const newMapData = processData(data.current, false, scenSize, varSelected);
			setMapData(newMapData);
			if (scenarioSize?.length == 1 && selectedCountriesForGraph?.length == 0) {
				const newMapDataHover = processData(data.current, true, scenSize, varSelected); // to get absolute values for scale but negative values on mouse
				setMapDataHover(newMapDataHover);
			}
			setLoading(false);
			setResetValues(false);
		} catch (e) {
			console.log(e);
		}
	};

	const processData = (newData: CSVData[], isAbsolute: boolean, scenSize?: any, varSelected?: any): MapData => {
		if (!isAbsolute) {
			processLineChart(newData, scenSize, lineGraphVariable, varSelected);
		}
		const filteredData: CSVData = newData.find(({ Date }) => Date === callbackSelectedYear)!;
		if (filteredData == null || filteredData == undefined) return [];
		return Object.entries(filteredData)
			.filter(([key, value]) => {
				// extracting the country code from the key
				// then returns the country
				// const [, countryCode] = key.split('_');
				const countryCode = key.substring(key.lastIndexOf('_') + 1);
				return !!countryMappings.find((obj) => obj['cnty-suffix'] === countryCode);
			})
			.map(([key, value]): [string, number] => {
				// const [, countryCode] = key.split('_');
				const countryCode = key.substring(key.lastIndexOf('_') + 1);

				let countryMapping = countryMappings.find((obj) => obj['cnty-suffix'] === countryCode);
				const countrySuffix = countryMapping?.['hc-key'] ?? '';

				return isAbsolute ? [countrySuffix, Math.abs(value)] : [countrySuffix, value];
			});
	};

	useEffect(() => {
		if (scenarioSize?.length) {
			variable?.map((varSelected: any) => {
				scenarioSize?.map((scenSize: any) => {
					fetchData(scenSize, varSelected);
				});
			});
		}
	}, [scenarioType, scenarioSize, variable, variables, dataPrefix, format]);

	const onAnimationChange = () => {
		variable?.map((varSelected: any) => {
			scenarioSize?.map((scenSize: any) => {
				const newMapData = processData(data.current, false, scenSize, varSelected);
				const newMapDataHover = processData(data.current, true, scenSize, varSelected); // to get absolute values for scale but negative values on mouse
				setMapData(newMapData);
				setMapDataHover(newMapDataHover);
			});
		});
	};

	useEffect(() => {
		setTitle(
			(variables.find(({ value }) => value === variable[variable?.length - 1])?.label || '') +
			(callbackSelectedYear ? ` - ${callbackSelectedYear}` : '')
		);
	}, [variable, variables, callbackSelectedYear]);

	return (
		<Layout>
			<Grid container spacing={2}>
				<Grid item xs={12} md={6} lg={7}>
					<Box display={'flex'} justifyContent="space-between" alignItems='center'>
						<Typography variant="h5" style={{ color: colourTitle, fontWeight: 'bold' }}>
							{pageTitle}
							<MUITooltip title={infoTextHead}>
								<InfoOutlined />
							</MUITooltip>
						</Typography>
						<Button
							className={styles.transparentButton}
							onClick={handleAllReset}
							size="small"
							style={{
								padding: 0,
								minWidth: '20px',
							}}
						>
							<MUITooltip title={infoTextReset}>
								<RotateLeftIcon fontSize="small" />
							</MUITooltip>
						</Button>
					</Box>

					<MapPageVariableSelection
						variables={variables}
						colour={colour}
						variable={variable}
						lineGraphVariable={lineGraphVariable}
						colourTitle={colourTitle}
						handleOnChangeClimateVariable={(value: any) => onChangeClimateVariable(value)}
						handleOnChangeLineGraphVariable={(value: any) => onChangeLineGraphVariable(value)}
					/>

					<MapPageWorldMap
						currentData={csvDataFromAllScen}
						colourTitle={colourTitle}
						scenarioSize={scenarioSize}
						format={format}
						type={userType}
						lineData={lineData}
						dataPrefix={dataPrefix}
						loading={loading}
						colourDark={colourDark}
						maxColour={maxColour}
						scenarioType={scenarioType}
						title={title}
						shockImpactStartYr={shockImpactStartYr}
						mapData={mapData}
						variable={variable}
						variables={variables}
						mapDataHover={mapDataHover}
						directionRef={directionRef.current}
						resetValues={resetValues}
						handleDirectionRefCallback={(refValue: any) => (directionRef.current = refValue)}
						handleLineDataCallback={(tempLineData: LineChartData[]) => setLineData(tempLineData)}
						handleScenSizeCallback={(tempScenSizeData: string[]) => setScenarioSize(tempScenSizeData)}
						handleAnimationCallback={onAnimationChange}
						handleAnimationClickCallback={(tempIsAniClicked: boolean) =>
							setCallbackIsAnimationClicked(tempIsAniClicked)
						}
						handleSelectedYearCallback={(tempYear: number) => setCallbackSelectedYear(tempYear)}
						handleCallbackSelectedCountries={(tempSelectedData: CSVData[]) => {
							if (lineGraphVariable.length > 0) {
								setLineGraphVariable([...lineGraphVariable]);
							}
							setSelectedCountriesForGraph(tempSelectedData);
						}}
					/>
				</Grid>
				<Grid item xs={12} md={6} lg={5}>
					<Grid container spacing={0}>
						<Grid item xs={12}>
							{/* MT attempt to allow MULTIPLE line plots on chart + showing Cen forecast */}
							{/* <ScenarioSelectionMultiple defaultValue="cen" showCentralForecast onSelectType={setScenarioType} onSelectSize={setScenarioSize}  />  */}

							{userType == 'student' ? (
								<ScenarioSelectionMultiple
									onSelectType={(type: any) => {
										const newShockImpactYr = getShockImpactYr(type);
										setSelectedCountriesForGraph([]);
										setLineGraphVariable(['WD']);
										directionRef.current = '';
										setCallbackSelectedYear(newShockImpactYr);
										setScenarioType(type);
									}}
									onSelectSize={setScenarioSize}
									showCentralForecast
									onSelectFormat={setFormat}
									showLevelDropDown={scenarioType !== 'cen' ? true : false}
									// removePercentDifference={
									// 	variable?.substring(variable.lastIndexOf('_') + 1, variable?.length).startsWith('z') ? true : false
									// }
									removePercentDifference={false}
									prefillDefaultValues={resetValues}
								/>
							) : (
								<BusinessScenarioSelectionMultiple
									onSelectType={(type: any) => {
										const newShockImpactYr = getShockImpactYr(type);
										setSelectedCountriesForGraph([]);
										setLineGraphVariable(['WD']);
										directionRef.current = '';
										setCallbackSelectedYear(newShockImpactYr);
										setScenarioType(type);
									}}
									onSelectSize={setScenarioSize}
									showCentralForecast
									onSelectFormat={setFormat}
									showLevelDropDown={scenarioType !== 'cen' ? true : false}
									// removePercentDifference={
									// 	variable?.substring(variable.lastIndexOf('_') + 1, variable?.length).startsWith('z') ? true : false
									// }
									removePercentDifference={false}
									prefillDefaultValues={resetValues}
								/>
							)}
						</Grid>
						<LineGraphMapPage
							animationClick={isCallbackAnimationClicked}
							colourTitle={colourTitle}
							dataPrefix={dataPrefix}
							lineData={lineData}
							selectedCountriesForGraph={selectedCountriesForGraph}
							variable={variable}
							minYear={minYear}
							colour={colour}
							directionRef={directionRef}
							scenarioType={scenarioType}
							format={format}
							scenarioSize={scenarioSize}
							loading={loading}
							type={userType}
							title={title}
							lineGraphVariablesSelected={lineGraphVariable}
						/>
					</Grid>
				</Grid>
			</Grid>
		</Layout>
	);
};

export default MapPageBusiness;
