import { Card, CardContent, Typography, Accordion, AccordionSummary, AccordionDetails, makeStyles } from '@material-ui/core';
import axios from 'axios';
import * as React from 'react';
import { useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import SingleNewsCard from './InfoStartNewsSingle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

interface CardProps {
	imgSrc?: any | [];
	title?: any;
	subTitle?: any;
	body?: any;
	objectFit?: string;
	borderColor?: string;
}

const InfoStartLatest: React.FC<CardProps> = ({ imgSrc, title, subTitle, body, objectFit, borderColor }) => {
	const [macroListings, setMacroListings] = useState([]);
	const [energyListings, setEnergyListings] = useState([]);
	const [GHGListings, setGHGListings] = useState([]);
	const [ESGListings, setESGListings] = useState([]);
	const [initialized, setInitialized] = useState(false);
	const [url, setUrl] = useState('');

	const corsUrl = 'https://api.rss2json.com/v1/api.json?rss_url=';
	const getFeedListing = (url: any) => axios.get(`${corsUrl}${url}`);

	const useStyles = makeStyles({
		accordionSummary: {
			minHeight: '28px !important',
			height: '28px !important',
			padding: 0,
			paddingLeft: 7,
			background: 'rgba(180, 180, 180,0.15)',
		},
		accordionTitle: {
			fontSize: '11px',
			fontWeight: 'bold',
			//			color: 'rgba(38,166,91,.8)',
			color: '#00a0a0',
			textTransform: 'uppercase',
		},
		listContainer: {
			display: 'block',
			padding: '4px',
			//			color: 'rgba(38,166,91,.8)',
			color: '#00a0a0',
		},
		mainList: {
			color: 'rgba(84,77,160,.7)',
			paddingLeft: '2px',
			fontWeight: 'bold',
			fontSize: '14px',
			cursor: 'pointer',
			listStyle: 'none',
			margin: 0,
			padding: 0
		},
		subList: {
			marginTop: '8px',
			paddingLeft: '20px',
		},
		listItem: {
			fontSize: '12px',
			marginBottom: '4px',
			cursor: 'pointer',
			listStyle: 'disc'
		},
		divider: {
			margin: '8px 0px 4px',
		},
		accordion: {
			border: '1px solid rgba(0, 0, 0, .2)',
			minHeight: '28px',
		}
	});

	const classes = useStyles();

	const getMacroListings = async (urlArray: any) => {
		let items: any = [];
		try {
			for (var i = 0; i < urlArray?.length; i++) {
				const response = await getFeedListing(urlArray[i]);
				response.data.items?.map((item: any) => {
					i === 0 ? (item.source = 'bls') : (item.source = 'bea');
					items.push(item);
				});
			}
			setMacroListings(items);
		} catch (ex) {
			console.log(ex);
		}
	};

	const getEnergyListings = async (urlArray: any) => {
		let items: any = [];
		try {
			for (var i = 0; i < urlArray?.length; i++) {
				const response = await getFeedListing(urlArray[i]);
				response.data.items?.map((item: any) => {
					item.source = 'eia';
					items.push(item);
				});
			}
			setEnergyListings(items);
		} catch (ex) {
			console.log(ex);
		}
	};

	const getGHGListings = async (urlArray: any) => {
		let items: any = [];
		try {
			for (var i = 0; i < urlArray?.length; i++) {
				const response = await getFeedListing(urlArray[i]);
				response.data.items?.map((item: any) => {
					item.source = 'ghg';
					items.push(item);
				});
			}
			setGHGListings(items);
		} catch (ex) {
			console.log(ex);
		}
	};

	const getESGListings = async (urlArray: any) => {
		let items: any = [];
		try {
			for (var i = 0; i < urlArray?.length; i++) {
				const response = await getFeedListing(urlArray[i]);
				response.data.items?.map((item: any) => {
					item.source = 'esg';
					items.push(item);
				});
			}
			setESGListings(items);
		} catch (ex) {
			console.log(ex);
		}
	};


	// Energy RSS Feeds
	// 'https://rss-feed-api.aws.jyllands-posten.dk/energywatch.eu/latest.xml'
	// 'https://renewablesnow.com/news/news_feed/?topic=technology.xml'
	//

	// GHG EMissions RSS Feeds - to be initiated
	//   https://feeds.emissieautoriteit.nl/nieuws.rss


	React.useEffect(() => {
		if (!initialized) {
			getMacroListings(['https://apps.bea.gov/rss/rss.xml']);
			//.rss link not working -  Maybe needs renaming to xml?
			// getMacroListings(['https://www.bls.gov/feed/bls_latest.rss', 'https://apps.bea.gov/rss/rss.xml']);
			getEnergyListings([
				'https://www.eia.gov/rss/todayinenergy.xml',
				'https://services.rechargenews.com/api/feed/rss',])
			getGHGListings([
				'https://feeds.feedburner.com/ConservationInternationalBlog/ClimateChange.xml',]);
			getESGListings([
				'https://www.esgtoday.com/feed/',]);
			//				'https://www.eco-business.com/feeds/news.xml',  ]);
			setInitialized(true);
		}
	}, []);

	return (
		<Card
			style={{
				boxShadow: '3px 3px 10px rgba(84,77,160,0.3) !important',
				borderRadius: '6px',
				border: '1px solid rgba(84,77,160,0.2)',
			}}
		>
			<CardContent
				style={{
					padding: '4px',
				}}
			>
				<Accordion className={classes.accordion}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
						className={classes.accordionSummary}
					>
						<Typography
							title="Latest Subject-relevant Docs"
							variant="body2"
							className={classes.accordionTitle}
						>
							WHO SHOULD USE APP ?
						</Typography>
					</AccordionSummary>
					<AccordionDetails
						className={classes.listContainer}
					>
						<ul
							title="Mouseover Text here"
							onClick={(e) => {
								e.stopPropagation();
								setTimeout(() => {
									window.open('https://next.mef-uk.com/who/', '_blank');
								}, 500);
							}}
							className={classes.mainList}
						>
							Overview:
							<ul className={classes.subList}>
								<li
									onClick={(e) => {
										e.stopPropagation();
										setTimeout(() => {
											window.open('https://next.mef-uk.com/who/exporters', '_blank');
										}, 500);
									}}
									className={classes.listItem}
								>Exporters/SMEs</li>
								<li
									onClick={(e) => {
										e.stopPropagation();
										setTimeout(() => {
											window.open('https://next.mef-uk.com/who/investment-managers', '_blank');
										}, 500);
									}}
									className={classes.listItem}
								>Investment/Fund Managers</li>
								<li onClick={(e) => {
									e.stopPropagation();
									setTimeout(() => {
										window.open('https://next.mef-uk.com/who/energy-analysts', '_blank');
									}, 500);
								}}
									className={classes.listItem}
								>Energy/Emissions Analysts</li>
								<li onClick={(e) => {
									e.stopPropagation();
									setTimeout(() => {
										window.open('https://next.mef-uk.com/who/higher-education-institutions', '_blank');
									}, 500);
								}}
									className={classes.listItem}
								>Higher Education Institutions/Students</li>
							</ul>
						</ul>
					</AccordionDetails>
				</Accordion>

				{/* <hr
					style={{
						margin: '8px 0px 4px',
					}} */}
				{/* /> */}
				<Accordion
					className={classes.accordion}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
						className={classes.accordionSummary}
					>
						<Typography title="US Energy Information Administration(EIA)-Data News Feed"
							variant="body2"
							className={classes.accordionTitle}
						>
							MEF MODELS
						</Typography>
					</AccordionSummary>
					<AccordionDetails className={classes.listContainer}>
						<ul onClick={(e) => {
							e.stopPropagation();
							setTimeout(() => {
								window.open('https://next.mef-uk.com/who/', '_blank');
							}, 500);
						}}
							className={classes.mainList}
						> MEF Model Suite:
							<ul className={classes.subList}>
								<li title="Mouseover Text here" onClick={(e) => {
									setTimeout(() => {
										e.stopPropagation();
										window.open('https://next.mef-uk.com/who/mGEM-global-macro', '_blank');
									}, 500);
								}}
									className={classes.listItem}
								>mGEM Global Macro model</li>
								<li title="Mouseover Text here" onClick={(e) => {
									e.stopPropagation();
									setTimeout(() => {
										window.open('https://next.mef-uk.com/who/finGEM-global-finance', '_blank');
									}, 500);
								}}
									className={classes.listItem}
								>finGEM Global Finance model</li>
								<li title="Mouseover Text here" onClick={(e) => {
									e.stopPropagation();
									setTimeout(() => {
										window.open('https://next.mef-uk.com/who/eGEM-global-emissions', '_blank');
									}, 500);
								}}
									className={classes.listItem}
								>eGEM Global Emissions model</li>
								<li title="Mouseover Text here" onClick={(e) => {
									e.stopPropagation();
									setTimeout(() => {
										window.open('https://next.mef-uk.com/who/enGEM-global-energy', '_blank');
									}, 500);
								}}
									className={classes.listItem}
								>enGEM Global Energy model</li>


								<li title="Mouseover Text here" onClick={(e) => {
									e.stopPropagation();
									setTimeout(() => {
										window.open('https://next.mef-uk.com/who/enGEM-global-energy', '_blank');
									}, 500);
								}}
									className={classes.listItem}
								>DEFMOD Global Defence Spending model</li>
							</ul>
						</ul>
					</AccordionDetails>
				</Accordion>

				{/* <hr
					style={{
						margin: '8px 0px 4px',
					}}
				/> */}
				<Accordion
					className={classes.accordion}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
						className={classes.accordionSummary}
					>
						<Typography title="Conservation International News Feed"
							variant="body2"
							style={{
								fontSize: '11px',
								fontWeight: 'bold',
								//								color: 'rgba(38,166,91,.8)',
								color: '#00a0a0',
								textTransform: 'uppercase',
							}}
						>
							DATA/SCENARIO UPDATES
						</Typography>
					</AccordionSummary>
					<AccordionDetails
						className={classes.listContainer}
					>
						<ul title="Mouseover Text here" className={classes.mainList} >
							Macro/Energy Data:
							<ul className={classes.subList}>
								<li
									onClick={(e) => {
										e.stopPropagation();
										setTimeout(() => {
											window.open('https://data-explorer.oecd.org/', '_blank');
										}, 500);
									}}
									className={classes.listItem}
								>OECD</li>
								<li
									onClick={(e) => {
										e.stopPropagation();
										setTimeout(() => {
											window.open('https://db.nomics.world/last-updates?tab=providers', '_blank');
										}, 500);
									}}
									className={classes.listItem}
								>DBNOMICS</li>
								<li
									onClick={(e) => {
										e.stopPropagation();
										setTimeout(() => {
											window.open('https://dashboard.neso.energy/genmix.html', '_blank');
										}, 500);
									}}
									className={classes.listItem}
								>NESO Dashboard(UK)</li>
							</ul>
							<ul title="Mouseover Text here" className={classes.mainList} >
								Electricity Data:
								<ul className={classes.subList}>
									<li
										onClick={(e) => {
											e.stopPropagation();
											setTimeout(() => {
												window.open('https://ember-energy.org/latest-updates/', '_blank');
											}, 500);
										}}
										className={classes.listItem}
									>Ember</li>
								</ul>
							</ul>
						</ul>
					</AccordionDetails>
				</Accordion>

				{/* <hr
					style={{
						margin: '8px 0px 4px',
					}}
				/> */}

				<Accordion
					className={classes.accordion}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
						className={classes.accordionSummary}
					>
						<Typography title="ESG Today-News Feed "
							variant="body2"
							style={{
								fontSize: '11px',
								fontWeight: 'bold',
								//								color: 'rgba(38,166,91,.8)',
								color: '#00a0a0',
								textTransform: 'uppercase',
							}}
						>
							PUBLICATIONS
						</Typography>
					</AccordionSummary>
					<AccordionDetails className={classes.mainList}>
						<ul className={classes.subList}>
							<li
								onClick={(e) => {
									e.stopPropagation();
									setTimeout(() => {
										window.open('https://next.mef-uk.com', '_blank');
									}, 500);
								}}
								className={classes.listItem}
							>Item1</li>
							<li
								onClick={(e) => {
									e.stopPropagation();
									setTimeout(() => {
										window.open('https://next.mef-uk.com', '_blank');
									}, 500);
								}}
								className={classes.listItem}
							>Item2</li>
						</ul>
					</AccordionDetails>
				</Accordion>
				{/* <hr
					style={{
						margin: '8px 0px 4px',
					}}
				/> */}

				<Accordion
					className={classes.accordion}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
						className={classes.accordionSummary}
					>
						<Typography title="ESG Today-News Feed "
							variant="body2"
							style={{
								fontSize: '11px',
								fontWeight: 'bold',
								//								color: 'rgba(38,166,91,.8)',
								color: '#00a0a0',
								textTransform: 'uppercase',
							}}
						>
							SUBSCRIBE
						</Typography>
					</AccordionSummary>
					<AccordionDetails className={classes.mainList}>
						<ul className={classes.subList}>
							<li
								onClick={(e) => {
									e.stopPropagation();
									setTimeout(() => {
										window.open('https://next.mef-uk.com/subscribe/education-subscribe', '_blank');
									}, 500);
								}}
								className={classes.listItem}
							>Commercial/SME</li>
							<li
								onClick={(e) => {
									e.stopPropagation();
									setTimeout(() => {
										window.open('https://next.mef-uk.com/subscribe/education-subscribe', '_blank');
									}, 500);
								}}
								className={classes.listItem}
							>Educational Institute</li>
						</ul>
					</AccordionDetails>
				</Accordion>
			</CardContent>
		</Card>
	);
};

export default InfoStartLatest;
