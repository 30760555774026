import React from 'react'
import { Box, makeStyles, Typography } from '@material-ui/core';
import InfoStartNews from './InfoStartNews';

interface Props {
  smUpScreen: boolean;
}

const InfoNews: React.FC<Props> = ({ smUpScreen }) => {

  const useStyles = makeStyles({

    newsHeader: {
      minHeight: '38px !important',
      height: '40px !important',
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      border: '1px solid rgba(0, 0, 0, .2)',
      borderRadius: '4px',
      width: '110px',
      minWidth: '110px',
      marginTop: '5px',
    },
    newsTitle: {
      textTransform: 'uppercase',
      marginLeft: '4px',
      color: '#544DA0',
      fontSize: '12px',
      fontWeight: 600,
    }
  });

  const classes = useStyles();

  return (
    <Box>
      <div className={classes.newsHeader}>
        <Typography
          variant="body2"
          className={classes.newsTitle}
        >
          NEWS
        </Typography>
      </div>
      <InfoStartNews />

    </Box>
  )
}
export default InfoNews;
