import { Avatar, Box, Grid, Grow, Link, Typography } from '@material-ui/core';
import * as React from 'react';
import PublicIcon from '@material-ui/icons/Public';
import BLSLogo from '../../images/US_BLS_logoOnly.jpg';
import BEALogo from '../../images/US_BEA_LogoOnly.jpg';
import EIALogo from '../../images/EIALogo.jpg';
import GHGLogo from '../../images/ConservationInternationalLogoONLY.png';
import ESGLogo from '../../images/EIALogo.jpg';

interface Props {
	newsInfo: any;
}
const SingleNewsCard: React.FC<Props> = ({ newsInfo }) => {
	const [showMore, setShowMore] = React.useState<boolean>(false);

	return (
		<Box
			style={{
				boxShadow: '3px 3px 10px rgba(84,77,160,0.3) !important',
				borderRadius: '6px',
				border: '1px solid rgba(84,77,160,0.5)',
				marginBottom: '8px',
			}}
		>
			{/* <Link href={newsInfo?.link} target={'_blank'}> */}
			<Grid
				container
				spacing={1}
				style={{
					alignItems: 'center',
				}}
			>
				<Grid
					item
					xs={2}
					style={{
						textAlign: 'center',
					}}
				>
					<Avatar
						style={{
							borderRadius: 0,
							height: '100%',
							width: '100%',
							background: 'transparent',
						}}
						alt={newsInfo?.author}
					>
						{newsInfo?.thumbnail?.length ? (
							<img alt="green iguana" height="45" width="100%" src={newsInfo?.thumbnail} />
						) : newsInfo?.source === 'bls' ? (
							<img alt="green iguana" height="45" width="100%" src={BLSLogo} />
						) : newsInfo?.source === 'eia' ? (
							<img alt="green iguana" height="45" width="100%" src={EIALogo} />
						) : newsInfo?.source === 'ghg' ? (
							<img alt="green iguana" height="45" width="100%" src={GHGLogo} />
						) : newsInfo?.source === 'esg' ? (
							<img alt="green iguana" height="45" width="100%" src={ESGLogo} />
						) :
						(

							<img
								alt="green iguana"
								height="45"
								width="100%"
								src={BEALogo}
								style={{
									objectFit: 'contain',
								}}
							/>
						)}
					</Avatar>
				</Grid>
				<Grid item xs={8}>
					<Typography
						variant="body2"
						component={'div'}
						style={{
							color: '#a08f4d',
							cursor: 'pointer',
							fontWeight: 'bold',
							fontSize: '12px',
						}}
						dangerouslySetInnerHTML={{ __html: newsInfo?.title }}
						onClick={() => {
							setShowMore(!showMore);
						}}
					></Typography>
				</Grid>
				{showMore && (
					<Grid item xs={12}>
						<Grow in={true}>
							<Typography
								variant="body2"
								component={'div'}
								style={{
									fontSize: '12px',
									display: 'grid',
									gridTemplateRows: '1fr',
									gridTemplateColumns: newsInfo?.source !== 'bls' ? '1fr' : '1fr 1fr',
									padding: '0px 6px 0px 6px',
								}}
								dangerouslySetInnerHTML={{ __html: newsInfo?.description }}
							></Typography>
						</Grow>
					</Grid>
				)}
			</Grid>
			{/* </Link> */}
		</Box>
	);
};

export default SingleNewsCard;
