import { DashStyleValue } from 'highcharts';

// for file names
export const sectorMap: Record<string, string> = {
	Airlines: 'Airl',
	Aluminium: 'Alum',
	Autos: 'Auto',
	Cement: 'Cem',
	'Diversified Mining': 'DMin',
	'Electricity Utilities': 'ElecU',
	'Oil & Gas': 'OilG',
	Paper: 'Pap',
	Shipping: 'Ship',
	Steel: 'Ste',
//	'Food Producers': 'FoodP',
//	Chemicals: 'Chem',

	//	Airlines: 'Airlin',
	// Autos: 'Autos',
	//	'Diversified Mining': 'DivMin',
	//	'Oil & Gas': 'OilGas',
	//	Paper: 'Paper',
	//	Steel: 'Steel',
	// Shipping: 'Ships',
	// Needed too ? (if so, which one ?)
	// Pap: 'Paper',
	// Paper: 'Pap',
};

export const formatMap: Record<string, string> = {
	GenGW: 'EnergyFlow (GW)',
    GenShare: 'Generation Share (%)',
    CapGW: 'Generation Capacity (GW)',
    CapShare: 'Generation Capacity Share (%)',
    //LCOE: 'Levelised Cost of Energy ($/Kwh)'
}

export const formatList: Record<string, string> = {
	DIF:  'Difference',
	PCT: '% Difference',
	LEV: 'Level',
}

export const tradeData: Record<string, string> = {
	exports: 'Exports',
	imports: 'Imports',
}

export const countryCountMap: Record<string, string> = {
	5: 'Top 5',
	10: 'Top 10',
	15: 'Top 15',
	20: 'Top 20',
	All: 'All Countries'
}

export const productListSITC: Record<string, string> = {
	11: 'All Products (G&S)',
	12: 'GOODS-TOTAL',
	0: 'FOOD-0',
	1: 'BEVERAGES-1',
	2: 'CRUDE MATERIALS-2',
	3: 'FUELS-3',
	4: 'VEGETABLE OILS-4',
	5: 'CHEMICALS-5',
	6: 'MATERIAL MANUFS-6.',
	7: 'MACHINERY/VEHICLES-7',
	8: 'OTHER MANUFS-8',
	9: 'UNSPECIFIED-9',
	10: 'SERVICES-TOTAL-10',
};

export const productColors: Record<string, string> = {
	11: '#1f77b4', // All Products (G&S) - Blue
	12: '#ff7f0e', // GOODS-TOTAL - Orange
	0: '#2ca02c', // FOOD-0 - Green
	1: '#d62728', // BEVERAGES-1 - Red
	2: '#9467bd', // CRUDE MATERIALS-2 - Purple
	3: '#8c564b', // FUELS-3 - Brown
	4: '#e377c2', // VEGETABLE OILS-4 - Pink
	5: '#7f7f7f', // CHEMICALS-5 - Grey
	6: '#bcbd22', // MATERIAL MANUFS-6. - Olive
	7: '#17becf', // MACHINERY/VEHICLES-7 - Teal
	8: '#ff9896', // OTHER MANUFS-8 - Light Red
	9: '#c5b0d5', // UNSPECIFIED-9 - Lavender
	10: '#f4a582', // SERVICES-TOTAL-10 - Peach
};

export const treeMapParentListSITC: any= [
	{
        "id": 0,
        "name": "FOOD"
    },
	{
        "id": 1,
        "name": "BEVERAGES"
    },
	{
        "id": 2,
        "name": "CRUDE MATERIALS"
    },
	{
        "id": 3,
        "name": "FUELS"
    },
	{
        "id": 4,
        "name": "VEGETABLE OILS"
    },
	{
        "id": 5,
        "name": "CHEMICALS"
    },
	{
        "id": 6,
        "name": "MATERIAL MANUFS."
    },
	{
        "id": 7,
        "name": "MACHINERY/VEHICLES"
    },
	{
        "id": 8,
        "name": "OTHER MANUFS"
    },
	{
        "id": 9,
        "name": "UNSPECIFIED"
    },
	{
        "id": 10,
        "name": "SERVICES"
    },

]

export const productListHS: Record<string, string> = {
	11: 'All Products (G&S)',
	12: 'GOODS-TOTAL',
	0: 'TEXTILES-0',
	1: 'AGRICULTURE-1',
	2: 'STONE-2',
	3: 'MINERALS-3',
	4: 'METALS-4',
	5: 'CHEMICALS-5',
	6: 'VEHICLES-6',
	7: 'MACHINERY-7',
	8: 'ELECTRONICS-8',
	9: 'OTHER-9',
	10: 'SERVICES-TOTAL-10',
};

export const treeMapParentListHS: any= [
	{
        "id": 0,
        "name": "TEXTILES"
    },
	{
        "id": 1,
        "name": "AGRICULTURE"
    },
	{
        "id": 2,
        "name": "STONE"
    },
	{
        "id": 3,
        "name": "MINERALS"
    },
	{
        "id": 4,
        "name": "METALS"
    },
	{
        "id": 5,
        "name": "CHEMICALS"
    },
	{
        "id": 6,
        "name": "VEHICLES"
    },
	{
        "id": 7,
        "name": "MACHINERY"
    },
	{
        "id": 8,
        "name": "ELECTRONICS"
    },
	{
        "id": 9,
        "name": "OTHER"
    },
	{
        "id": 10,
        "name": "SERVICES"
    },

]

export const bm_sector_map: Record<string, string> = {
	Airlines: 'tpiAirl_IntlPl',
	Aluminium: 'tpiAlum_u2Deg',
	Autos: 'tpiAuto_2DegSI',
	Cement: 'tpiCem_u2Deg',
	//	'Diversified Mining': 'tpiDMin_NatPl',
	'Diversified Mining': 'tpiDMin_u2Deg',
	'Electricity Utilities': 'tpiElecU_u2Deg',
	'Oil & Gas': 'tpiOilG_u2Deg',
	Paper: 'tpiPap_2Deg',
	Shipping: 'tpiShip_2Deg',
	Steel: 'tpiSte_2Deg',
//	Chemicals: 'tpiChem_2Deg',
// 'Food Producers': 'tpiFoodP_2Deg',

};

export const sub_sec_parent_map: Record<string, string> = {
	Steel: 'Manufacturing',
	Cement: 'Manufacturing',
	OtherManuf: 'Manufacturing',
	Gas: 'Energy',
	Oil: 'Energy',
	Coal: 'Energy',
	'Information/Communication': 'Services',
	'Real Estate': 'Services',
	'Prof. Services': 'Services',
	'Public Sector': 'Services',
	'Finance/Insurance': 'Services',
	'Other Services': 'Services',
	Distribution: 'Services',
	Shipping: 'Services',
};

export const sub_ED_parent_map: Record<string, string> = {
	Industry: 'Industry',
	Residential: 'Residential',
	Commercial: 'Commercial',
	Transport: 'Transport',
	'Electricity Generation': 'Electricity Generation',
	GenerationCapacity: 'Generation Capacity',

	// 'Electricity Generation-Renewables': 'Renewables',
};

export const parentFFSKeyMappings: any = {
	'Energy-Electricity Utilities': { key1: 'elecP' },
	'Coal-Coal Mining': { key1: 'coalP' },
	'Oil-Oil & Gas Distribution': { key1: 'gasG', key2: 'petrG' },
	'Oil-Oil & Gas': { key1: 'gasP', key2: 'petrP' },
};

export const FFS_map: Record<string, string> = {
	FFS: 'FFS$tot',
	'FFS%': 'FFS%tot',
	FFSpc: 'FFS$totPC',
	coalP: 'Producers',
	gasP: 'Producers',
	petrP: 'Producers',
	elecP: 'Producers',
	coalG: 'General Service',
	gasG: 'General Service',
	petrG: 'General Service',
	elecG: 'General Service',
	coalC: 'Consumers',
	gasC: 'Consumers',
	petrC: 'Consumers',
	elecC: 'Consumers',
	coalPpc: 'Producers',
	gasPpc: 'Producers',
	petrPpc: 'Producers',
	elecPpc: 'Producers',
	coalGpc: 'General Service',
	gasGpc: 'General Service',
	petrGpc: 'General Service',
	elecGpc: 'General Service',
	coalCpc: 'Consumers',
	gasCpc: 'Consumers',
	petrCpc: 'Consumers',
	elecCpc: 'Consumers',
	coalPC: 'Coal',
	gasPC: 'Gas',
	petrPC: 'Petroleum',
	elecPC: 'Electricity',
};

export const sub_FFS_parent_map: Record<string, string> = {
	FFS$tot: 'Total',
	'FFS%tot': 'Total',
	FFS$totPC: 'Total',
	FFS$coal: 'Coal',
	FFS$elec: 'Electricity',
	FFS$gas: 'Gas',
	FFS$petr: 'Petroleum',
	'FFS%coal': 'Coal',
	'FFS%elec': 'Electricity',
	'FFS%gas': 'Gas',
	'FFS%petr': 'Petroleum',
	FFS$coalpc: 'Coal',
	FFS$elecpc: 'Electricity',
	FFS$gaspc: 'Gas',
	FFS$petrpc: 'Petroleum',
};

export const sub_GDP_parent_map: Record<string, string> = {
	'GDP-Domestic Demand': 'Domestic Demand',
	// GDP: 'GDP',
	'GDP-TradeAveXM': 'Average Exports/Imports',
	'TradeAveXM-Exports': 'Exports',
	'TradeAveXM-Imports': 'Imports',
	'Domestic Demand-Consumption': 'Consumption',
	'Domestic Demand-Public Consumption': 'Public Consumption',
	'Domestic Demand-Fixed Investment': 'Fixed Investment',
	'Domestic Demand-Stockbuilding': 'Stockbuilding',
	'Fixed Investment-Business Investment': 'Business Investment',
	'Fixed Investment-Public Investment': 'Public Investment',
	'Fixed Investment-Residential Investment': 'Residential Investment',

	// 		Consumption: 'Consumption',
	// 		'Public Consumption': 'Public Consumption',
	// 		'Fixed Investment': 'Fixed Investment',
	// 		Stockbuilding: 'Stockbuilding',
	// 		'Business Investment': 'Business Investment',
	// 		'Public Investment': 'Public Investment',
	// 		'Residential Investment': 'Residential Investment',
};

export const datatype_name_map: Record<string, string> = {
	xGHGsect: 'CI',
	//	TPIfirms-C: 'CI Cumulative Level',
	//	TPIfirms-CD: 'CI Cumulative Discrepancy',
	//	TPIfirms-D: 'CI Discrepancy',
	'TPIfirms-C': 'CI Cumulative Level',
	'TPIfirms-CD': 'CI Cumulative Discrepancy',
	'TPIfirms-D': 'CI Discrepancy',
};

export const data_type_map: Record<string, string> = {
	xGHGsect: '',
	//	TPIfirms-C: 'C',
	//	TPIfirms-CD: 'CD',
	//	TPIfirms-D: 'D',
	//
    // 	TPIfirms-C: 'C',
	// TPIfirms-CD: 'CD',
	// TPIfirms-D: 'D',
// 'TPIfirms-C': '#C',
// 'TPIfirms-CD': '#CD',
// 'TPIfirms-D': '#D',
//
'TPIfirms-C': '-C',
'TPIfirms-CD': '-CD',
'TPIfirms-D': '-D',


// 2024 TPI Update - Rollback for now
//	TPIfirms-C: '-C',
//	TPIfirms-CD: '-CD',
//	TPIfirms-D: '-D',
};

export const linePatterns: DashStyleValue[] = [
	'Solid',
	'ShortDash',
	'ShortDot',
	'ShortDashDot',
	'ShortDashDotDot',
	'Dot',
	'Dash',
	'LongDash',
	'DashDot',
	'LongDashDot',
	'LongDashDotDot',
];
export const getLineStyle: any = {
	a: 'Solid',
	b: 'Dot',
	c: 'Dash',
	d: 'DashDot',
};

export const portfolioMappings: Record<string, string> = {
	PassivePortfolio: '-------------------------------- Top5 Passive Portfolios: ',
	divider: '------------ Vanguard',
	esgMeanVG20_k: 'Vanguard Cautious (20% Equity)',
	esgMeanVG60_k: 'Vanguard Balanced (60% Equity)',
	esgMeanVG100_k: 'Vanguard Growth (100% Equity)',
	divider1: '------------ Psigma',
	esgMeanPSG40_k: 'Psigma Cautious (40% Equity)',
	esgMeanPSG60_k: 'Psigma Balanced (60% Equity)',
	ActivePortfolio: '-------------------------------- Top5 Active Portfolios: ',
	divider2: '------------ Generic-TBA',
	// esgMeanAC20_k: 'UltraCautious (20% Equity) - N/A',
	// esgMeanAC40_k: 'Active Portfolio-Safe (40% Equity) - N/A',
	// esgMeanAC60_k: 'Active Portfolio-Balanced (60% Equity) - N/A',
	// esgMeanAC80_k: 'Active Portfolio-Growth (80% Equity) - N/A',
	// esgMeanAC100_k: 'Psigma Balanced-SuperGrowth (100% Equity) - N/A',

	//	FNDnf01_k: 'PPG Cautious-cash level',
//	FNDnf06_k: 'PPG Balanced-cash level',
//	FNDnf11_k: 'PPG Growth-cash level',
//	FNDf01_k: 'PPG Cautious-real level',
//	FNDf06_k: 'PPG Balanced-real level',
//	FNDf11_k: 'PPG Growth-real level',
//	PSG60STL_k: 'Psigma Balanced-real level',
//	PSG60nSTL_k: 'Psigma Balanced-cash level',

// ----------------  ETF Portfolios - Sterling Denominated (London Listing)
dividerETF01:'---------- ETF Portfolios, £ terms (cash)',		
dividerETF02:'---------- ETF Funds-London, £ terms (cash)',		
dividerETF03:'--- Equity, £ terms (cash):',		
dividerETF04:'--- Fixed Income, £ terms (cash):',		
dividerETF05:'--- ESG Funds, £ terms (cash)',		

PORTesg01_gbp: 'PORTesg01-Cash Terms-(ESG) 77% Equity, 13% MMF, 10% Commodities',
	PORT01_gbp: 'PORT01-Cash Terms-(Non-ESG) -77% Equity, 13% MMF, 10% Commodities',
	PORTesg01c_gbp: 'PORTesg01c-Real Terms-(ESG) 77% Equity, 13% MMF, 10% Commodities',
	PORT01c_gbp: 'PORT01c-Real Terms-(Non-ESG) -77% Equity, 13% MMF, 10% Commodities',
// ----------------  ETFs - Sterling Denominated (London Listing)
	XSX6_gbp: 'XSX6-Xtrackers STOXX Europe 600 UCITS ETF 1C',
	V3AB_gbp: 'V3AB-Vanguard ESG Global All Cap UCITS ETF',
	SJPA_gbp: 'SJPA-iShares Core MSCI Japan IMI UCITS ETF-London £',
	UC15_gbp: 'UC15-UBS CMCI Composite (Commods)',
	IWDP_gbp: 'IWDP-iShares Developed Markets Property Yield',
	e500P_gbp: '500P-Franklin S&P 500 Paris Aligned Climate - London £',
	SGLN_gbp: 'SGLN-iShares Physical Gold',
	EGOV_gbp: 'EGOV-UBS J.P. Morgan Global Government ESG Liquid Bond',
	VETA_gbp: 'VETA-Vanguard Eurozone Government Bonds',
	CSH2_gbp: 'CSH2-Lyxor Smart Cash-ETF-London £',
	SAEU_gbp: 'SAEU-iShares MSCI Europe ESG Screened ETF-London £',
	SEGM_gbp: 'SEGM-iShares MSCI EM IMI ESG ScreenedETF-London £',
	SGJP_gbp: 'SGJP-iShares MSCI Japan ESG Screened ETF-London £',

};

export const ShockVarCodes: Record<string, string> = {
	// listing of All ShockVariables used in each scenario
	sf1: 'RSH_u',
	sf2: 'xWPOIL',
	sf3: 'BCONFman_u',
	sf4: 'xGFIN_u',
	sf5: '',
	sf6: '',
	sf7: '',
	sf8: '',
	sf9: '',
	sf10: '',
	bf1: 'RSH_u',
	bf2: 'PSM_u',
	bf3: 'BCONFlev_u',
	bf4: 'X_u',
	bf5: '',
};

export const ShockVarNameTypeAndColorCodes: any = {
	// listing of All ShockVariables and its type names and corresponding colors used in each scenario

		// ------ Supply Variables
//	xBCONFman: { name: 'Supply',  varDesc: 'Business Confidence (Manufacturing)', equationType: 'Exoogenous', color: 'rgba(114, 140, 212, 0.7)'  },
    BCONFlev: { name: 'Supply',  varDesc: 'Business Confidence Level (Manufacturing)', equationType: 'Endogenous', color: 'rgba(114, 140, 212, 0.7)'  },
    BCONFman: { name: 'Supply',  varDesc: 'Business Confidence (Manufacturing)', equationType: 'Endogenous', color: 'rgba(114, 140, 212, 0.7)'  },
	COIL: { name: 'Supply',  varDesc: 'Consumption of Oil Products (mbd))', equationType: 'Endogenous', color: 'rgba(114, 140, 212, 0.7)'  },
	EPR: { name: 'Supply',  varDesc: 'Private Sector Employment', equationType: 'Behavioural', color: 'rgba(114, 140, 212, 0.7)'  },
	IBUS: { name: 'Supply',  varDesc: 'Real Business Investment', equationType: 'Behavioural', color: 'rgba(114, 140, 212, 0.7)'  },
	IBUSGDP: { name: 'Supply',  varDesc: 'Real Business Investment:GDP ratio', equationType: 'Behavioural', color: 'rgba(147, 117, 243, 0.7)'  },
	KS: { name: 'Supply',  varDesc: 'Real Capital Stock (Whole Economy)', equationType: 'Identity', color: 'rgba(114, 140, 212, 0.7)'  },
	IF: { name: 'Supply',  varDesc: 'Real Total Fixed Investment', equationType: 'Identity', color: 'rgba(114, 140, 212, 0.7)'  },
	YGAP: { name: 'Supply',  varDesc: '% Output Gap - ((GDP-Potential)/Potential) ', equationType: 'Behavioural', color: 'rgba(147, 117, 243, 0.7)'  },
	BCONFman_WD: { name: 'Supply',  varDesc: 'World Business Confidence (Manufacturing)', equationType: 'Endogenous', color: 'rgba(114, 140, 212, 0.7)'  },
	// ------ Demand Variables
	C: { name: 'Demand',  varDesc: 'Real Consumption', equationType: 'Behavioural', color: 'rgba(147, 117, 243, 0.7)' },
	CD: { name: 'Demand',  varDesc: 'Real Consumer Durable Expenditure', equationType: 'Behavioural', color: 'rgba(147, 117, 243, 0.7)'  },
	CND: { name: 'Demand',  varDesc: 'Real Consumer Non-Durable Expenditure', equationType: 'Behavioural', color: 'rgba(147, 117, 243, 0.7)'  },
	Cn: { name: 'Demand',  varDesc: 'Nominal Consumption', equationType: 'Behavioural', color: 'rgba(147, 117, 243, 0.7)' },
	CDn: { name: 'Demand',  varDesc: 'Nominal Consumer Durable Expenditure', equationType: 'Behavioural', color: 'rgba(147, 117, 243, 0.7)'  },
	CNDn: { name: 'Demand',  varDesc: 'Nominal Consumer Non-Durable Expenditure', equationType: 'Behavioural', color: 'rgba(147, 117, 243, 0.7)'  },
	DOMD: { name: 'Demand',  varDesc: 'Real Domestic Demand', equationType: 'Identity', color: 'rgba(147, 117, 243, 0.7)' },
	GDP: { name: 'Demand',  varDesc: 'Real Gross Domestic Product', equationType: 'Identity', color: 'rgba(147, 117, 243, 0.7)'  },
	TFE: { name: 'Demand',  varDesc: 'Real Total Final Expenditure', equationType: 'Identity', color: 'rgba(147, 117, 243, 0.7)'  },
	DOMDn: { name: 'Demand',  varDesc: 'Nominal  Domestic Demand', equationType: 'Identity', color: 'rgba(147, 117, 243, 0.7)'  },
	GDPn: { name: 'Demand',  varDesc: 'Nominal  Gross Domestic Product', equationType: 'Identity', color: 'rgba(147, 117, 243, 0.7)'  },
	TFEn: { name: 'Demand',  varDesc: 'Nominal  Total Final Expenditure', equationType: 'Identity', color: 'rgba(147, 117, 243, 0.7)'  },
	IRES: { name: 'Demand',  varDesc: 'Real Residential Investment', equationType: 'Behavioural', color: 'rgba(147, 117, 243, 0.7)'  },
	//	IBUS: { name: 'Demand',  varDesc: 'Real Business Investment', equationType: 'Behavioural', color: 'rgba(147, 117, 243, 0.7)',},
	SB: { name: 'Demand',  varDesc: 'Real Inventory Accumulation', equationType: 'Behavioural', color: 'rgba(147, 117, 243, 0.7)'  },
	ST: { name: 'Demand',  varDesc: 'Real Inventory Stock Level', equationType: 'Behavioural', color: 'rgba(147, 117, 243, 0.7)'  },
	STGDP: { name: 'Demand',  varDesc: 'Inventory Stock:GDP Ratio', equationType: 'Behavioural', color: 'rgba(147, 117, 243, 0.7)'  },
	YOILI: { name: 'Demand',  varDesc: 'Output of oil & gas extraction sector', equationType: 'Behavioural', color: 'rgba(147, 117, 243, 0.7)'  },
	YPOT: { name: 'Demand',  varDesc: 'Potential Output', equationType: 'Behavioural', color: 'rgba(147, 117, 243, 0.7)' },
	PSDY: { name: 'Demand',  varDesc: 'Real Personal Sector Disposable Income', equationType: 'Identity', color: 'rgba(78, 228, 78, 0.7)'  },
	PSDYn: { name: 'Demand',  varDesc: 'Nominal Personal Sector Disposable Income', equationType: 'Identity', color: 'rgba(78, 228, 78, 0.7)'  },
	UNEARN: { name: 'Demand',  varDesc: 'Nominal Personal Sector Dividend+Interest Income', equationType: 'Identity', color: 'rgba(78, 228, 78, 0.7)'  },
	DOMD$p10_EMU: { name: 'Demand',  varDesc: 'Eurozone Real Domestic Demand', equationType: 'Identity', color: 'rgba(147, 117, 243, 0.7)'  },
	//	EMUDOMD: { name: 'Demand',varDesc: 'Eurozone Real Domestic Demand', equationType: 'Identity',color: 'rgba(147, 117, 243, 0.7)', },
	// ------ Price Variables
	CPI: { name: 'Price',  varDesc: 'Core Price Level', equationType: 'Behavioural', color: 'rgba(147, 117, 243, 0.7)' },
	CPIF: { name: 'Price',  varDesc: 'Fuel/Energy Price Level', equationType: 'Behavioural', color: 'rgba(147, 117, 243, 0.7)'  },
	CPIX: { name: 'Price',  varDesc: 'Core Price Level', equationType: 'Behavioural', color: 'rgba(147, 117, 243, 0.7)' },
	CPICORE: { name: 'Price',  varDesc: 'Core Price Level (excl. Food & Energy Prices)', equationType: 'Behavioural', color: 'rgba(147, 117, 243, 0.7)'  },
	DCX: { name: 'Price',  varDesc: 'Core Personal Consumption Deflator ', equationType: 'Behavioural', color: 'rgba(147, 117, 243, 0.7)'  },
	DGDP: { name: 'Price',  varDesc: 'GDP Deflator', equationType: 'Behavioural', color: 'rgba(147, 117, 243, 0.7)' },
	DM: { name: 'Price',  varDesc: 'Import Deflator', equationType: 'Behavioural', color: 'rgba(147, 117, 243, 0.7)' },
	DXF: { name: 'Price',  varDesc: 'Fuel Goods Export Deflator', equationType: 'Behavioural', color: 'rgba(147, 117, 243, 0.7)'  },
	ER: { name: 'Price',  varDesc: 'Earnings', equationType: 'Behavioural', color: 'rgba(147, 117, 243, 0.7)' },
	PHC: { name: 'Price',  varDesc: 'Commercial Property Prices Index ', equationType: 'Behavioural', color: 'rgba(147, 117, 243, 0.7)'  },
	PH: { name: 'Price',  varDesc: 'Residential Property Prices Index ', equationType: 'Behavioural', color: 'rgba(147, 117, 243, 0.7)'  },
	PHcpi: { name: 'Price',  varDesc: 'Real House Prices (CPI deflator)', equationType: 'Behavioural', color: 'rgba(147, 117, 243, 0.7)'  },
	//	PHcpi: { name: 'Price',  varDesc: 'Real House Prices (CPI deflator)', equationType: 'Behavioural', color: 'rgba(147, 117, 243, 0.7)' },
	PPI: { name: 'Price',  varDesc: 'Producer Price Level', equationType: 'Behavioural', color: 'rgba(147, 117, 243, 0.7)'  },
	PSM: { name: 'Price',  varDesc: 'Equity Price Index', equationType: 'Behavioural', color: 'rgba(147, 117, 243, 0.7)' },
	PSMcpi: { name: 'Price',  varDesc: 'Real Equity Price Index (CPI deflated)', equationType: 'Behavioural', color: 'rgba(147, 117, 243, 0.7)'  },
	// ------ Price Variables- World
	xWPOIL: { name: 'Price',  varDesc: 'Global oil price $pp', equationType: 'Exogenous', color: 'rgba(147, 117, 243, 0.7)'  },
	WDPagri: { name: 'Price',  varDesc: 'Weighted Global Prices: Agriculture', equationType: 'Behavioural', color: 'rgba(147, 117, 243, 0.7)'  },
	WDPcons: { name: 'Price',  varDesc: 'Weighted Global Prices: Construction', equationType: 'Behavioural', color: 'rgba(147, 117, 243, 0.7)'  },
	WDPdist: { name: 'Price',  varDesc: 'Weighted Global Prices: Distribution', equationType: 'Behavioural', color: 'rgba(147, 117, 243, 0.7)'  },
	WDPener: { name: 'Price',  varDesc: 'Weighted Global Prices: Energy', equationType: 'Behavioural', color: 'rgba(147, 117, 243, 0.7)'  },
	WDPfini: { name: 'Price',  varDesc: 'Weighted Global Prices: Finance/Insurance', equationType: 'Behavioural', color: 'rgba(147, 117, 243, 0.7)'  },
	WDPinfc: { name: 'Price',  varDesc: 'Weighted Global Prices: Information & Communication', equationType: 'Behavioural', color: 'rgba(147, 117, 243, 0.7)'  },
	WDPmanu: { name: 'Price',  varDesc: 'Weighted Global Prices: Manufacturing', equationType: 'Behavioural', color: 'rgba(147, 117, 243, 0.7)'  },
	WDPoths: { name: 'Price',  varDesc: 'Weighted Global Prices: Other Services', equationType: 'Behavioural', color: 'rgba(147, 117, 243, 0.7)'  },
	WDPprof: { name: 'Price',  varDesc: 'Weighted Global Prices: Prof. Services', equationType: 'Behavioural', color: 'rgba(147, 117, 243, 0.7)'  },
	WDPpubl: { name: 'Price',  varDesc: 'Weighted Global Prices: Public Sector', equationType: 'Behavioural', color: 'rgba(147, 117, 243, 0.7)'  },
	WDPreal: { name: 'Price',  varDesc: 'Weighted Global Prices: Real Estate', equationType: 'Behavioural', color: 'rgba(147, 117, 243, 0.7)'  },
	WDPserv: { name: 'Price',  varDesc: 'Weighted Global Prices: Service Sector', equationType: 'Behavioural', color: 'rgba(147, 117, 243, 0.7)'  },
	// ------ Monetary Varaibles
	ERIb: { name: 'Monetary',  varDesc: 'Effective Exchange Rate', equationType: 'Behavioural', color: 'rgba(233, 94, 60, 0.7)'  },
	MCI: { name: 'Monetary',  varDesc: 'Monetary Conditions Index (1990=100) ', equationType: 'Identity', color: 'rgba(233, 94, 60, 0.7)'  },
	MOND: { name: 'Monetary',  varDesc: 'Broad Money Demand', equationType: 'Identity', color: 'rgba(233, 94, 60, 0.7)' },
	MRSH: { name: 'Monetary',  varDesc: 'Major Economies - short-term interest rate (OBR Model)', equationType: 'Taylor rule', color: 'rgba(233, 94, 60, 0.7)'  },
	RSH_OECD: { name: 'Monetary',  varDesc: 'OECD Short term interest rates (GDP-weighted)', equationType: 'Taylor rule', color: 'rgba(233, 94, 60, 0.7)'  },
	RGAP: { name: 'Monetary',  varDesc: 'Yield Gap (Long Rates - Short Rates)', equationType: 'Taylor rule', color: 'rgba(233, 94, 60, 0.7)'  },
	RLO: { name: 'Monetary',  varDesc: 'Bond Yields (10yr Benchmark)', equationType: 'Behavioural', color: 'rgba(233, 94, 60, 0.7)'  },
	RRLO: { name: 'Monetary',  varDesc: 'Real Bond Yields (10yr Benchmark, CPI-deflated)', equationType: 'Behavioural', color: 'rgba(233, 94, 60, 0.7)'  },
	RORL: { name: 'Monetary',  varDesc: 'Rate of Return on Liabilities held Overseas', equationType: 'Taylor rule', color: 'rgba(233, 94, 60, 0.7)'  },
	RRSH: { name: 'Monetary',  varDesc: 'Real Short-Term Interest Rate (CPI deflator)', equationType: 'Taylor rule-Deflated', color: 'rgba(233, 94, 60, 0.7)'  },
	RRTR: { name: 'Monetary',  varDesc: 'Expected Long-Run Real Federal Funds Rate', equationType: 'Identity', color: 'rgba(233, 94, 60, 0.7)'  },
	RSH: { name: 'Monetary',  varDesc: 'Short term interest rates', equationType: 'Taylor rule', color: 'rgba(233, 94, 60, 0.7)'  },
	RSHi: { name: 'Monetary',  varDesc: 'Short term interest rates Index (non-negative)', equationType: 'Taylor rule', color: 'rgba(233, 94, 60, 0.7)'  },
	RSHspr: { name: 'Monetary',  varDesc: 'Short term interest rate spread on Overnight Rate)', equationType: 'Taylor rule', color: 'rgba(233, 94, 60, 0.7)'  },
	RSHcb: { name: 'Monetary',  varDesc: 'Official/Repo Interest rate (Central Bank) ', equationType: 'Taylor rule', color: 'rgba(233, 94, 60, 0.7)'  },
	RSHdifu: { name: 'Monetary',  varDesc: 'Differential from US Short Term Interest Rates', equationType: 'Identity', color: 'rgba(233, 94, 60, 0.7)'  },
	RBBB: { name: 'Monetary',  varDesc: 'S&P BBB CORPORATE BOND RATE [FRB USModel]', equationType: 'Behavioural', color: 'rgba(233, 94, 60, 0.7)'  },
	RRSHX: { name: 'Monetary',  varDesc: 'Relative Short Rates rel. to World Ave RSH excl. Home Country', equationType: 'Identity', color: 'rgba(233, 94, 60, 0.7)'  },
	RRLOX: { name: 'Monetary',  varDesc: 'Relative 10yr Bond Yield rel. to World Ave 10yr Bond Yield excl. Home Country', equationType: 'Identity-Taylor rule', color: 'rgba(233, 94, 60, 0.7)'  },
	WDRBDEPX$: { name: 'Monetary',  varDesc: 'World Bank Deposit Rate Proxy excluding Country selected', equationType: 'Taylor rule', color: 'rgba(233, 94, 60, 0.7)'  },
	RSH$_WD: { name: 'Monetary',  varDesc: 'World Short term interest rates (GDP-weighted)', equationType: 'Taylor rule', color: 'rgba(233, 94, 60, 0.7)'  },
	RSH_WD: { name: 'Monetary',  varDesc: 'World Short term interest rates (GDP-weighted)', equationType: 'Taylor rule', color: 'rgba(233, 94, 60, 0.7)'  },
	RSH_EMU: { name: 'Monetary',  varDesc: 'ECB Short Term Interest Rates', equationType: 'Taylor Rule', color: 'rgba(233, 94, 60, 0.7)'  },
	RSH_G7: { name: 'Monetary',  varDesc: 'G7 Short term interest rates (GDP-weighted)', equationType: 'Taylor rule', color: 'rgba(233, 94, 60, 0.7)'  },
	WDRSHX$: { name: 'Monetary',  varDesc: 'World Short term interest rates (GDP-weighted) excl. Home Country', equationType: 'Taylor rule', color: 'rgba(233, 94, 60, 0.7)'  },
	WDRLOX$: { name: 'Monetary',  varDesc: 'World 10yr Bond Yield  (GDP-weighted) excl. Home Country', equationType: 'Taylor rule', color: 'rgba(233, 94, 60, 0.7)'  },
	// ------ Financial Variables
	PSDIR: { name: 'Financial',  varDesc: 'Personal Sector Debt Interest Receipts', equationType: 'Identity', color: 'rgba(78, 228, 78, 0.7)'  },
	PSDIP: { name: 'Financial',  varDesc: 'Personal Sector Debt Interest Payments', equationType: 'Identity', color: 'rgba(78, 228, 78, 0.7)'  },
	PSNIR: { name: 'Financial',  varDesc: 'Personal Sector, Net Interest Receipts', equationType: 'Identity', color: 'rgba(78, 228, 78, 0.7)'  },
	CSNIR: { name: 'Financial',  varDesc: 'Corporate Sector, Net Interest Receipts', equationType: 'Identity', color: 'rgba(78, 228, 78, 0.7)'  },
	PSNFW: { name: 'Financial',  varDesc: 'Personal Sector Net Financial Wealth', equationType: 'Identity', color: 'rgba(78, 228, 78, 0.7)'  },
	PSNAFA: { name: 'Financial',  varDesc: 'Personal Sector Net Accumulation of Financial Assets', equationType: 'Identity', color: 'rgba(78, 228, 78, 0.7)'  },
	RXD: { name: 'Financial',  varDesc: 'Bilateral US$ Exchange Rate (LCU per $)', equationType: 'UIP Term', color: 'rgba(78, 228, 78, 0.7)'  },
	RXD_EMU: { name: 'Financial',  varDesc: 'Eurozone Bilateral US$ Exchange Rate (LCU per $)', equationType: 'UIP Term', color: 'rgba(78, 228, 78, 0.7)'  },
	//	EMURXD: {n ame: 'Financial',varDesc: 'Eurozone Bilateral US$ Exchange Rate (LCU per $)',equationType: 'UIP Term',color: 'rgba(78, 228, 78, 0.7)', },
	// ------ Fiscal Variables
	GFEXP: { name: 'Fiscal',  varDesc: 'Federal Total Govt. Expenditure - Cash Terms', equationType: 'Exogenous-Policy', color: 'rgba(206, 149, 75, 0.7)'  },
	GFREV: { name: 'Fiscal',  varDesc: 'Federal Total Govt. Revenue - Cash Terms', equationType: 'Exogenous-Policy', color: 'rgba(206, 149, 75, 0.7)'  },
	GSEXP: { name: 'Fiscal',  varDesc: 'State & Local  Total Govt. Expenditure - Cash Terms', equationType: 'Exogenous-Policy', color: 'rgba(206, 149, 75, 0.7)'  },
	GSREV: { name: 'Fiscal',  varDesc: 'State & Local  Total Govt. Revenue - Cash Terms', equationType: 'Exogenous-Policy', color: 'rgba(206, 149, 75, 0.7)'  },
	GFNIP: { name: 'Fiscal',  varDesc: 'Federal Net Public Debt Service Payments', equationType: 'Behavioural', color: 'rgba(206, 149, 75, 0.7)'  },
	GFGIP: { name: 'Fiscal',  varDesc: 'Federal Public Debt Service', equationType: 'Behavioural', color: 'rgba(206, 149, 75, 0.7)'  },
	GFI: { name: 'Fiscal',  varDesc: 'Federal Govt. Total Investment - Constant Prices', equationType: 'Exogenous-Policy', color: 'rgba(206, 149, 75, 0.7)'  },
	GFN: { name: 'Fiscal',  varDesc: 'Total Federal Non-Defense purchases, const. prices', equationType: 'Exogenous-Policy', color: 'rgba(206, 149, 75, 0.7)'  },
	GGIP: { name: 'Fiscal',  varDesc: 'Public Debt Service', equationType: 'Behavioural', color: 'rgba(206, 149, 75, 0.7)'  },
	GI: { name: 'Fiscal',  varDesc: 'Public Investment-Nominal', equationType: 'Exogenous-Policy', color: 'rgba(206, 149, 75, 0.7)'  },
	GSGIP: { name: 'Fiscal',  varDesc: 'State & Local Public Debt Service', equationType: 'Behavioural', color: 'rgba(206, 149, 75, 0.7)'  },
	xGFIN: { name: 'Fiscal',  varDesc: 'Federal Govt. Public Investment: Non-Defence', equationType: 'Exogenous-Policy', color: 'rgba(206, 149, 75, 0.7)'  },
	xGCGI: { name: 'Fiscal',  varDesc: 'Real Public Investment+Consumption', equationType: 'Exogenous-Identity', color: 'rgba(206, 149, 75, 0.7)'  },
	TFY: { name: 'Fiscal',  varDesc: 'Federal Income Tax Yield', equationType: 'Identity-Policy', color: 'rgba(206, 149, 75, 0.7)'  },
	TSY: { name: 'Fiscal',  varDesc: 'State & Local Income Tax Yield', equationType: 'Identity-Policy', color: 'rgba(206, 149, 75, 0.7)'  },
	// ------ Trade Variables
	X: { name: 'Trade',  varDesc: 'Total Export Volumes', equationType: 'Behavioural', color: 'rgba(172, 172, 172, 0.7)' },
	M: { name: 'Trade',  varDesc: 'Total Import Volumes', equationType: 'Behavioural', color: 'rgba(172, 172, 172, 0.7)' },
	NETX: { name: 'Trade',  varDesc: 'Net Exports', equationType: 'Identity', color: 'rgba(172, 172, 172, 0.7)' }, 
	
};

export const MacroLinksVariableTypes: Record<string, any> = {
	all: {
		name: 'All',
		color: 'rgba(0,0,0,0.7)',
	},
	price: {
		name: 'Price',
		color: 'rgba(147, 117, 243, 1)',
	},
	trade: {
		name: 'Trade',
		color: 'rgba(172, 172, 172, 1)',
	},
	financial: {
		name: 'Financial',
		color: 'rgba(78, 228, 78, 1)',
	},
	supply: {
		name: 'Supply',
		color: 'rgba(114, 140, 212, 1)',
	},
	demand: {
		name: 'Demand',
		color: ' rgba(147, 117, 243, 1)',
	},
	monetary: {
		name: 'Monetary',
		color: 'rgba(233, 94, 60, 1)',
	},
	fiscal: {
		name: 'Fiscal',
		color: 'rgba(206, 149, 75, 1)',
	},
};

export const PortfolioCardNames: Record<string, string> = {
	equity: 'Equity',
	corporateBonds: 'Corporate Bonds',
	sovereignBonds: 'Sovereign Bonds',
	residentialProperty: 'Property: Residential',
	commercialProperty: 'Property: Commercial',
	commodities: 'commodities',
};

export const PortfolioCardColors: Record<string, string> = {
	equity: 'rgba(84,77,160,1.0)',
	corporateBonds: 'rgba(221,110,16,1.0)',
	sovereignBonds: 'rgba(46,139,87,1.0)',
	residentialProperty: 'rgba(0,115,237,1.0)',
	commercialProperty: 'rgba(114, 190, 0,1.0)',
	commodities: 'rgba(255, 49, 224,1.0)',
	portfolio: '#000000',
};

export const GetIndexesFromKeys: any = {
	equity: '1',
	corporateBonds: '2',
	sovereignBonds: '3',
	residentialProperty: '4',
	commercialProperty: '5',
	commodities: '6',
};

export const generateColor = (index?: any) => {
	let randomColorString = '';
	let r = 0;
	let g = 0;
	let b = 102;
	var max: any = Math.max(Math.max(r, Math.max(g, b)), 1) * 10;
	var step: any = 255 / max;
	// randomColorString = r * step * index, g * step * index, b * step * index

	//   const arrayOfColorFunctions = "0123456789abcdef";
	//   for (let x = 0; x < 6; x++) {
	//     let index = Math.floor(Math.random() * 16);
	//     let value = arrayOfColorFunctions[index];
	//     randomColorString += value;
	//   }
	return randomColorString;
};

export const newColorFind = (id: any) => {
	const colorMap: any = {};
	const selectedColors: any = {};
	// If already generated and assigned, return
	if (colorMap[id]) return colorMap[id];

	// Generate new random color
	let newColor;

	do {
		newColor = generateColor();
	} while (selectedColors[newColor]);

	// Found a new random, unassigned color
	colorMap[id] = newColor;
	selectedColors[newColor] = true;

	// Return next new color
	return newColor;
};

export const DepartmentNames = {
	name: 'SubjectsEconRelated.json',
	description: 'This file contains a list of allsubject areas related to Economics.',
	version: 1,
	subjects: [
		{
			department: 'Accountancy',
			name: 'Accountancy',
		},
		{
			department: 'Business Administration',
			name: 'Business Administration',
		},
		{
			department: 'Data Science/AI',
			name: 'Data Science/AI',
		},
		{
			department: 'Environmental Studies',
			name: 'Environmental Studies',
		},
		{
			department: 'Economics',
			name: 'Economics',
		},
		{
			department: 'Economics',
			name: 'International Economics and Finance',
		},
		{
			department: 'Finance',
			name: 'Finance',
		},
		{
			department: 'Political Science',
			name: 'Political Science',
		},
		{
			department: 'Portfolio Management',
			name: 'Portfolio Management',
		},
		{
			department: 'Sociology',
			name: 'Sociology',
		},
	],
};
