import React from 'react'
import { Box } from '@material-ui/core';
import InfoStartLatest from './InfoStartLatest';

export default function InfoInformationAndLatest() {
  return (
    <Box>
      <h5
        style={{
          color: '#544DA0',
          fontSize: '14px',
          fontWeight: 600,
          paddingLeft: '4px',
          textTransform: 'uppercase',
        }}
      >
        INFORMATION & LATEST
      </h5>
      <InfoStartLatest />
    </Box>
  )
}
