import { Card, CardContent, Typography, Accordion, AccordionSummary, AccordionDetails, useMediaQuery } from '@material-ui/core';
import axios from 'axios';
import * as React from 'react';
import { useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import SingleNewsCard from './InfoStartNewsSingle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core';
import theme from '../../config/theme';

interface CardProps {
	imgSrc?: any | [];
	title?: any;
	subTitle?: any;
	body?: any;
	objectFit?: string;
	borderColor?: string;
}

const useStyles = makeStyles({
	accordionSummary: {
		minHeight: '38px !important',
		height: '38px !important',
		padding: 0,

		'& .MuiAccordionSummary-content': {
			margin: '0 !important',
			height: '38px !important',
			alignItems: 'center'
		},
		'& .MuiAccordionSummary-expandIcon': {
			padding: '8px !important',
			height: '38px !important',
			marginRight: 0
		}
	},
	macro: {
		background: 'rgba(84,77,160,0.20)',
	},
	energy: {
		background: 'rgba(221,110,16,0.2)',
	},
	emissions: {
		background: 'rgba(46,139,87,0.2)',
	},
	finance: {
		background: 'rgba(0,115,237,0.2)',
	},
	general: {
		background: 'rgba(211, 211, 211, 0.3)'
	},
	accordianHeading: {
		fontSize: '11px',
		marginBottom: '2px',
		fontWeight: 'bold',
		textTransform: 'uppercase',
		marginLeft: '2px',
	},
	accordion: {
		margin: '0 !important',
		'& .MuiAccordionDetails-root': {
			padding: '4px !important',
			marginTop: '0 !important'
		},
		'& .MuiCollapse-container': {
			margin: '0 !important'
		},
		'& .MuiCollapse-wrapper': {
			margin: '0 !important'
		}
	},

	divider: {
		margin: '4px 0 4px 0 !important',
		padding: 0,
		height: '1px'
	}
});

const macroNews = [
	{
		thumbnail:
			'https://img.freepik.com/free-photo/beautiful-scenery-phragmites-plants-by-sea-with-swimming-pelican-sunset_181624-37787.jpg?w=2000',
		title: 'Emissions Headlines-Source1',
	},
	{
		thumbnail:
			'https://img.freepik.com/free-photo/beautiful-scenery-phragmites-plants-by-sea-with-swimming-pelican-sunset_181624-37787.jpg?w=2000',
		title: 'Emissions Headlines-Source2',
	},
];
const InfoStartNews: React.FC<CardProps> = ({ imgSrc, title, subTitle, body, objectFit, borderColor }) => {
	const [macroListings, setMacroListings] = useState([]);
	const [energyListings, setEnergyListings] = useState([]);
	const [GHGListings, setGHGListings] = useState([]);
	const [ESGListings, setESGListings] = useState([]);
	const [initialized, setInitialized] = useState(false);
	const [url, setUrl] = useState('');
	const [isAnyExpanded, setIsAnyExpanded] = useState(false);
	const [openAccordions, setOpenAccordions] = useState<Set<string>>(new Set());

	const corsUrl = 'https://api.rss2json.com/v1/api.json?rss_url=';
	const getFeedListing = (url: any) => axios.get(`${corsUrl}${url}`);
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const getMacroListings = async (urlArray: any) => {
		let items: any = [];
		try {
			for (var i = 0; i < urlArray?.length; i++) {
				const response = await getFeedListing(urlArray[i]);
				response.data.items?.map((item: any) => {
					i === 0 ? (item.source = 'bls') : (item.source = 'bea');
					items.push(item);
				});
			}
			setMacroListings(items);
		} catch (ex) {
			console.log(ex);
		}
	};

	const getEnergyListings = async (urlArray: any) => {
		let items: any = [];
		try {
			for (var i = 0; i < urlArray?.length; i++) {
				const response = await getFeedListing(urlArray[i]);
				response.data.items?.map((item: any) => {
					item.source = 'eia';
					items.push(item);
				});
			}
			setEnergyListings(items);
		} catch (ex) {
			console.log(ex);
		}
	};

	const getGHGListings = async (urlArray: any) => {
		let items: any = [];
		try {
			for (var i = 0; i < urlArray?.length; i++) {
				const response = await getFeedListing(urlArray[i]);
				response.data.items?.map((item: any) => {
					item.source = 'ghg';
					items.push(item);
				});
			}
			setGHGListings(items);
		} catch (ex) {
			console.log(ex);
		}
	};

	const getESGListings = async (urlArray: any) => {
		let items: any = [];
		try {
			for (var i = 0; i < urlArray?.length; i++) {
				const response = await getFeedListing(urlArray[i]);
				response.data.items?.map((item: any) => {
					item.source = 'esg';
					items.push(item);
				});
			}
			setESGListings(items);
		} catch (ex) {
			console.log(ex);
		}
	};


	// Energy RSS Feeds
	// 'https://rss-feed-api.aws.jyllands-posten.dk/energywatch.eu/latest.xml'
	// 'https://renewablesnow.com/news/news_feed/?topic=technology.xml'
	//

	// GHG EMissions RSS Feeds - to be initiated
	//   https://feeds.emissieautoriteit.nl/nieuws.rss


	React.useEffect(() => {
		if (!initialized) {
			getMacroListings(['https://apps.bea.gov/rss/rss.xml']);
			//.rss link not working -  Maybe needs renaming to xml?
			// getMacroListings(['https://www.bls.gov/feed/bls_latest.rss', 'https://apps.bea.gov/rss/rss.xml']);
			getEnergyListings([
				'https://www.eia.gov/rss/todayinenergy.xml',
				'https://services.rechargenews.com/api/feed/rss',])
			getGHGListings([
				'https://feeds.feedburner.com/ConservationInternationalBlog/ClimateChange.xml',]);
			getESGListings([
				'https://www.esgtoday.com/feed/',]);
			//				'https://www.eco-business.com/feeds/news.xml',  ]);
			setInitialized(true);
		}
	}, []);

	const classes = useStyles();

	const handleAccordionChange = (panel: string) => (event: React.ChangeEvent<{}>, expanded: boolean) => {
		const newOpenAccordions = new Set(openAccordions);
		if (expanded) {
			newOpenAccordions.add(panel);
		} else {
			newOpenAccordions.delete(panel);
		}
		setOpenAccordions(newOpenAccordions);
		setIsAnyExpanded(newOpenAccordions.size > 0);
	};

	return (
		<Card
			style={{
				boxShadow: '3px 3px 10px rgba(84,77,160,0.3) !important',
				borderRadius: '6px',
				border: '1px solid rgba(84,77,160,0.2)',
				width: isAnyExpanded ? (isMobile ? '100%' : '170%') : '110px',
				minWidth: isAnyExpanded ? (isMobile ? '100%' : '170%') : '110px',
				transition: 'width 0.3s, min-width 0.3s'
			}}
		>
			<CardContent
				style={{
					padding: '4px',
				}}
			>
				<Accordion
					className={classes.accordion}
					style={{
						border: '1px solid rgba(0, 0, 0, .2)',
					}}
					onChange={handleAccordionChange('macro')}
				>
					<AccordionSummary
						className={`${classes.accordionSummary} ${classes.macro}`}
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography title="US Bureau of Economic Analysis(BEA)-Data News Feed"
							variant="body2"
							style={{
								fontSize: '11px',
								marginBottom: '2px',
								fontWeight: 'bold',
								color: 'rgba(84,77,160,1)',
								textTransform: 'uppercase',
								marginLeft: '4px'
							}}
						>
							Macro
						</Typography>
					</AccordionSummary>
					<AccordionDetails
						style={{
							display: 'block',
							padding: '4px',
						}}
					>
						{macroListings?.map((newsInfo: any, index: number) => (
							<SingleNewsCard newsInfo={newsInfo} key={index} />
						))}
					</AccordionDetails>
				</Accordion>

				<hr
					className={classes.divider}
				/>
				<Accordion
					className={classes.accordion}
					style={{
						border: '1px solid rgba(0, 0, 0, .2)',
					}}
					onChange={handleAccordionChange('energy')}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
						className={`${classes.accordionSummary} ${classes.energy}`}
					>
						<Typography title="US Energy Information Administration(EIA)-Data News Feed"
							variant="body2"
							style={{
								fontSize: '11px',
								marginBottom: '2px',
								fontWeight: 'bold',
								color: 'rgba(221,110,16,1)',
								textTransform: 'uppercase',
								marginLeft: '4px'
							}}
						>
							Energy
						</Typography>
					</AccordionSummary>
					<AccordionDetails
						style={{
							display: 'block',
							padding: '4px',
						}}
					>
						{energyListings?.map((newsInfo: any, index: number) => (
							<SingleNewsCard newsInfo={newsInfo} key={index} />
						))}
					</AccordionDetails>
				</Accordion>

				<hr
					className={classes.divider}
				/>
				<Accordion
					className={classes.accordion}
					style={{
						border: '1px solid rgba(0, 0, 0, .2)',
					}}
					onChange={handleAccordionChange('emissions')}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
						className={`${classes.accordionSummary} ${classes.emissions}`}
					>
						<Typography title="Conservation International News Feed"
							variant="body2"
							style={{
								fontSize: '11px',
								marginBottom: '2px',
								fontWeight: 'bold',
								color: 'rgba(46,139,87,1)',
								textTransform: 'uppercase',
								marginLeft: '4px'
							}}
						>
							Emissions
						</Typography>
					</AccordionSummary>
					<AccordionDetails
						style={{
							display: 'block',
							padding: '4px',
						}}
					>
						{GHGListings?.map((newsInfo: any, index: number) => (
							<SingleNewsCard newsInfo={newsInfo} key={index} />
						))}
					</AccordionDetails>
				</Accordion>
				<hr
					className={classes.divider}
				/>

				<Accordion
					className={classes.accordion}
					style={{
						border: '1px solid rgba(0, 0, 0, .2)',
					}}
					onChange={handleAccordionChange('finance')}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
						className={`${classes.accordionSummary} ${classes.finance}`}
					>
						<Typography title="ESG Today-News Feed "
							variant="body2"
							style={{
								fontSize: '11px',
								marginBottom: '2px',
								fontWeight: 'bold',
								color: 'rgba(0,115,237,1)',
								textTransform: 'uppercase',
								marginLeft: '4px'
							}}
						>
							Finance
						</Typography>
					</AccordionSummary>
					<AccordionDetails
						style={{
							display: 'block',
							padding: '4px',
						}}
					>
						{ESGListings?.map((newsInfo: any, index: number) => (
							<SingleNewsCard newsInfo={newsInfo} key={index} />
						))}
					</AccordionDetails>
				</Accordion>
				<hr
					className={classes.divider}
				/>

				<Accordion
					className={classes.accordion}
					style={{
						border: '1px solid rgba(0, 0, 0, .2)',
					}}
					onChange={handleAccordionChange('general')}
				>
					<AccordionSummary
						className={`${classes.accordionSummary} ${classes.general}`}
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography title="US Bureau of Economic Analysis(BEA)-Data News Feed"
							variant="body2"
							style={{
								fontSize: '11px',
								marginBottom: '2px',
								fontWeight: 'bold',
								color: 'rgba(84,77,160,1)',
								textTransform: 'uppercase',
								marginLeft: '4px'
							}}
						>
							General
						</Typography>
					</AccordionSummary>
					<AccordionDetails
						style={{
							display: 'block',
							padding: '4px',
						}}
					>
						{macroListings?.map((newsInfo: any, index: number) => (
							<SingleNewsCard newsInfo={newsInfo} key={index} />
						))}
					</AccordionDetails>
				</Accordion>

			</CardContent>
		</Card>
	);
};

export default InfoStartNews;
