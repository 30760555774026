import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Box, Grid, useMediaQuery } from '@material-ui/core';
import theme from '../../config/theme';

interface AccordionProps {
	title: any;
	categoryDescription: string;
	body?: any;
	color?: any;
	background?: any;
	accessIcon?: any;
	header?: boolean;
	history?: any;
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	heading: {
		fontSize: theme.typography.pxToRem(12),
		fontWeight: 'bold',
		textTransform: 'uppercase',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			fontSize: theme.typography.pxToRem(11),
			wordWrap: 'break-word',
			alignItems: 'center',
			textTransform: 'uppercase',
		}
	},
	headingText: {
		fontSize: theme.typography.pxToRem(12),
		textTransform: 'uppercase',
		[theme.breakpoints.down('sm')]: {
			fontSize: theme.typography.pxToRem(11),
		}
	},
	accessText: {
		fontSize: theme.typography.pxToRem(12),
		textTransform: 'uppercase',
		fontWeight: 'bold',
		[theme.breakpoints.down('sm')]: {
			fontSize: theme.typography.pxToRem(11),  // Match mobile heading size
		}
	},
	titleContainer: {
		[theme.breakpoints.down('sm')]: {
			width: '30% !important',
			maxWidth: '30% !important',
			flexBasis: '30% !important'
		}
	},
	descriptionContainer: {
		[theme.breakpoints.down('sm')]: {
			width: '60% !important',
			maxWidth: '60% !important',
			flexBasis: '60% !important'
		}
	},
	accessContainer: {
		[theme.breakpoints.down('sm')]: {
			width: '10% !important',
			maxWidth: '10% !important',
			flexBasis: '10% !important'
		}
	},
	headerTitleContainer: {
		[theme.breakpoints.down('sm')]: {
			width: '30% !important',
			maxWidth: '30% !important',
			flexBasis: '30% !important'
		}
	},
	headerDescriptionContainer: {
		[theme.breakpoints.down('sm')]: {
			width: '55% !important',
			maxWidth: '55% !important',
			flexBasis: '55% !important'
		}
	},
	headerAccessContainer: {
		[theme.breakpoints.down('sm')]: {
			width: '15% !important',
			maxWidth: '15% !important',
			flexBasis: '15% !important'
		}
	}
}));

const AccordionForInfoPage: React.FC<AccordionProps> = ({
	title,
	body,
	color,
	background,
	categoryDescription,
	accessIcon,
	header,
	history,
}) => {
	const classes = useStyles();
	const smUpScreen = useMediaQuery(theme.breakpoints.up('sm'));

	return (
		<div className={classes.root}>
			<Accordion
				elevation={1}
				style={{
					border: '1px solid rgba(0, 0, 0, .2)',
				}}
			>
				<AccordionSummary
					expandIcon={!header && <ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header"
					style={{
						minHeight: 'min-content',
						height: smUpScreen ? '40px' : 'max-content',
						padding: 0,
						paddingLeft: smUpScreen ? 16 : 8,
						alignItems: smUpScreen ? 'center' : 'flex-start',
					}}
				>
					<Grid container spacing={smUpScreen ? 2 : 1}>
						<Grid
							item
							xs={10}
							md={3}
							className={header ? classes.headerTitleContainer : classes.titleContainer}
							style={{
								background: background,
								borderRadius: '4px',
								paddingRight: smUpScreen ? 0 : '4px',
							}}
						>
							<Typography
								className={classes.heading}
								style={{
									color: color,
								}}
							>
								{title} {!smUpScreen && accessIcon}
							</Typography>
						</Grid>
						<Grid
							item
							xs={2}
							md={1}
							className={header ? classes.headerAccessContainer : classes.accessContainer}
							style={{
								color: color,
								fontWeight: 'bold',
							}}
						>
							{header ? (
								<Typography className={classes.accessText} style={{ color: color, lineHeight: -1, alignItems: 'center', }}>
									Access:
								</Typography>
							) : (
								<div style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									width: '100%'
								}}>
									{accessIcon}
								</div>
							)}
						</Grid>

						<Grid
							item
							xs={12}
							md={8}
							className={header ? classes.headerDescriptionContainer : classes.descriptionContainer}
							style={{
								paddingLeft: smUpScreen ? 0 : '4px',
								backgroundColor: background,
								borderRadius: '4px',
								marginTop: !smUpScreen ? '8px' : '0',
							}}
						>
							<Typography
								className={classes.headingText}
								style={{
									color: color,
									marginLeft: header ? '5px' : '15px',
									fontWeight: 'bold',
								}}
							>
								{categoryDescription}
							</Typography>
						</Grid>
					</Grid>
				</AccordionSummary>
				{!header && (
					<AccordionDetails>
						<Grid container spacing={1}>
							<Grid item xs={4} md={2} style={{ color: '#a08f4d', fontWeight: 600 }}>
								Tab Name
							</Grid>

							<Grid item xs={8} md={10} style={{ color: '#a08f4d', fontWeight: 600 }}>
								Tab Description
							</Grid>
							{body?.map((data: any, index: number) => (
								<>
									<Grid
										item
										xs={4}
										md={2}
										key={index}
										style={{
											color: color,
											background: index % 2 == 0 ? background : '#fff',
											display: 'flex',
											alignItems: 'center',
											gap: '8px'
										}}
									>
										{data.imgSrc && (
											<img
												src={data.imgSrc}
												alt=""
												style={{
													width: '20px',
													height: '25px !impotant',
													objectFit: 'contain',
												}}
											/>
										)}
										<Typography
											style={{
												fontWeight: 600,
												fontSize: '0.875rem',
											}}
										>
											{data?.name}
										</Typography>
									</Grid>

									<Grid
										item
										xs={8}
										md={10}
										style={{ color: color, background: index % 2 == 0 ? background : '#fff', fontSize: '0.875rem' }}
									>
										<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
											<span>{data.description}</span>
											{data.demoButton && (
												<button
													onClick={() => {
														history.push(data.demoButton?.route);
														setTimeout(() => {
															window.open(
																data.demoButton?.url,
																'_blank',
																'width=600,height=400,scrollbars=no,resizable=yes'
															);
														}, 500);
													}}
													style={{
														backgroundColor: color,
														marginLeft: 'auto',
														color: '#ffffff',
														fontWeight: 'bold',
														border: 'yes',
														borderRadius: '4px',
														padding: '2px 6px',
														cursor: 'pointer',
														fontSize: '0.65em',
														marginRight: 10,
														minHeight: '18px',
														lineHeight: '14px'
													}}
												>
													Demo
												</button>
											)}
										</div>
									</Grid>
								</>
							))}
						</Grid>
					</AccordionDetails>
				)}
			</Accordion>
		</div>
	);
};

export default AccordionForInfoPage;
