// see   C:\data\EIA\Model_WEPS\weps\CNTY_REGION_MAPPING.123   for Country/Zone listings,member-country suffixes & lookups
// --------------------------------------------------------------------------  EIA: IEO Regional Blocs
//		anz: {	name: 'Australia/N Zealand',	prefix: 'anz',},
//		CSA: {	name: 'Non-OECD:Americas-Other',	prefix: 'CSA',},
//		EUR: {	name: 'OECD Europe',	prefix: 'EUR',},
//		MID: {	name: 'Non-OECD: Middle-East',	prefix: 'MID',},
//		OAS: {	name: 'Non-OECD Asia',	prefix: 'OAS',},
//		URA: {	name: 'Non-OECD Europe/Eurasia:Other',	prefix: 'URA',},
// anz=[_a,_nz]
// EUR=[_at,_be,_cz,_dk,_ee,_fi,_fr,_de,_gr,_hu,_is,_ie,_il,_it,_lv,_lt,_lu,_nl,_no,_pl,_pt,_sk,_si,_es,_se,_ch,_tr,_gb]
// URA=[_al,_am,_az,_by,_ba,_bg,_hr,_cy,_fo,_ge,_kz,_kg,_mk,_ml,_md,_me,_ro,_rs,_tj,_tm,_ua,_uz]
// OAS=[_af,_bd,_bt,_bn,_kh,_nz,_fj,_hk,_id,_kp,_la,_my,_mn,_mm,_nr,_np,_fr,_pk,_pg,_ph,_ws,_sg,_sb,_lk,_tw,_th,_tl,_vu,_vn]
// MID=[_bh,_ir,_iq,_jo,_kw,_lb,_om,_qa,_sa,_sy,_ae,_ye]
// AFR=[_dz,_ao,_bj,_bw,_bf,_bi,_cv,_cm,_cf,_td,_km,_cg,_cd,_ci,_dj,_eg,_gq,_er,_et,_ga,_gm,_gh,_gn,_gw,_ke,_ls,_lr,_ly,_mg,_mw,_ml,_mr,_mu,_ma,_mz,_na,_ne,_ng,_rw,_st,_sn,_sc,_sl,_so,_za,_ss,_sd,_sz,_tz,_tg,_tn,_ug,_eh,_zm,_zw]
// CSA=[_aq,_ag,_ar,_bs,_bb,_bz,_bo,_cr,_cu,_dm,_do,_ec,_sv,_gl,_gd,_gt,_gy,_ht,_hn,_jm,_ni,_pa,_py,_pe,_sr,_tt,_uy,_ve]


// Australasia
export const ANZ = ['au', 'nz'];
//Europe
export const EUR = ['at','be','cz','dk','ee','fi','fr','de','gr','hu','is','ie','il','it','lv','lt','lu','nl','no','pl','pt','sk','si','es','se','ch','tr','gb',];
// Europe/Eurasia
//export const URA = ['al','am','az','by','ba','bg','hr','cy','fo','ge','kz','kg','mk','ml','md','me','ro','rs','tj','tm','ua','uz',];
export const URA = ['al','am','az','by','ba','bg','hr','cy','fo','ge','kz','kg','mk','md','me','ro','rs','tj','tm','ua','uz',];
// Non-OECD Asia
export const OAS = ['af','bd','bt','bn','kh','nz','fj','hk','id','kp','la','my','mn','mm','nr','np','pk','pg','ph','ws','sg','sb','lk','tw','th','tl','vu','vn',];
// Middle East
export const MID = ['bh', 'ir', 'iq', 'jo', 'kw', 'lb', 'om', 'qa', 'sa', 'sy', 'ae', 'ye'];
//Africa
export const AFR = ['dz','ao','bj','bw','bf','bi','cv','cm','cf','td','km','cg','cd','ci','dj','eg','gq','er','et','ga','gm','gh','gn','gw','ke','ls','lr','ly','mg','mw','ml','mr','mu','ma','mz','na','ne','ng','rw','st','sn','sc','sl','so','za','ss','sd','sz','tz','tg','tn','ug','eh','zm','zw',];
// Central/Southern America
export const CSA = ['aq','ag','ar','bs','bb','bz','bo','cr','cu','dm','do','ec','sv','gl','gd','gt','gy','ht','hn','jm','ni','pa','py','pe','sr','tt','uy','ve',];
