import React from 'react'
import { Box } from '@material-ui/core';
import CRELM_toothpaste from '../../images/CRELM_Toothpaste.webp';
import Senaryo_Logo from '../../images/Senaryo_Logo_large.png';

export default function InfoAdvertisement() {
  return (
    <Box
      style={{
        width: '100%',
        height: '120px',
        textAlign: 'center',
        border: '1px solid #cccccc',
        borderRadius: '12px',
        marginBottom: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '12px',
      }}
    >
      <img
        // src={CRELM_toothpaste}
        src={Senaryo_Logo}        
        alt="Advertisement"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          borderRadius: '12px'
        }}
      />

    </Box>
  )
}
