import React from 'react'
import { Box } from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';
import MontecarloGreen from '../../images/Montecarlo_EmissionsGreen.jpg';
import TradeProds from '../../images/TradeProdsUS.jpg';
import TradeFlows from '../../images/MacroTradeFlows.jpg';
import GHGMap from '../../images/GHGMap.jpg';
import GHGtimeSeries from '../../images/GHGtimeSeries.jpg';
import FinMapBlue from '../../images/FinMapBlue.jpg';
import ScenarioResponses from '../../images/ScenarioResponsesLine.jpg';
import MacroSunburst from '../../images/Macro_Sunbusrt.jpg';
import EnergyFlow from '../../images/Energy_Flow.jpg';
import EnergyMap from '../../images/EnergyMap_Orange.jpg';
import EnergyTrade from '../../images/EnergyTradeChord.jpg';
import MacroMapIcon from '../../images/MacroMapScreenshot.jpg';
import EmissionsPieIcon from '../../images/EmissionsPieGreenScreenshot.jpg';
import TPItreeMapIcon from '../../images/CI_TPIfirmsTreemapScreenshot.jpg';

export default function InfoCarouselImages() {
  return (
    <Box>
      <Box>
        <Box
          style={{
            width: '100%',
            height: '135px',
            textAlign: 'center',
            border: '1px solid #cccccc',
            borderRadius: '12px',
            marginBottom: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '12px',
          }}
        >
          <Carousel autoPlay={true} stopAutoPlayOnHover navButtonsAlwaysInvisible={true} indicators={false}>
            {[TradeProds, EnergyMap, MontecarloGreen, EnergyTrade, GHGtimeSeries].map((img, index) => (
              <img
                key={index}
                src={img}
                alt={`Scenario ${index + 1}`}
                style={{ maxHeight: '128px', maxWidth: '95%' }}
              />
            ))}
          </Carousel>
        </Box>
      </Box>
      <Box>
        <Box
          style={{
            width: '100%',
            height: '135px',
            textAlign: 'center',
            border: '1px solid #cccccc',
            borderRadius: '12px',
            marginBottom: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '12px',
          }}
        >
          <Carousel autoPlay={true} stopAutoPlayOnHover navButtonsAlwaysInvisible={true} indicators={false}>
            {[GHGMap, MacroMapIcon, EmissionsPieIcon, TPItreeMapIcon, FinMapBlue].map((img, index) => (
              <img
                key={index}
                src={img}
                alt={`Scenario ${index + 1}`}
                style={{ maxHeight: '128px', maxWidth: '95%' }}
              />
            ))}
          </Carousel>
        </Box>
      </Box>
      <Box>
        <Box
          style={{
            width: '100%',
            height: '135px',
            textAlign: 'center',
            border: '1px solid #cccccc',
            borderRadius: '12px',
            marginBottom: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '12px',
          }}
        >
          <Carousel autoPlay={true} stopAutoPlayOnHover navButtonsAlwaysInvisible={true} indicators={false}>
            {[MacroSunburst, TradeFlows, EnergyFlow, ScenarioResponses].map((img, index) => (
              <img
                key={index}
                src={img}
                alt={`Scenario ${index + 1}`}
                style={{ maxHeight: '128px', maxWidth: '95%' }}
              />
            ))}
          </Carousel>
        </Box>
      </Box>
    </Box>
  )
}
