export function hideDataTable(attempts:number) {
  for (let i = 0; i < attempts; i++) {
      const menuItems = document.getElementsByClassName('highcharts-menu-item');
      const toggleDataTableMenuItem = Array.from(menuItems).find(
          (el) => el.textContent?.includes('Hide data table')
      );

      if (toggleDataTableMenuItem) {
          const click = new MouseEvent('click', {
              view: window,
              bubbles: true,
              cancelable: true,
          });
          toggleDataTableMenuItem.dispatchEvent(click);
      } else {
          console.log(`Attempt ${i + 1}: Menu item not found.`);
      }
  }
}
